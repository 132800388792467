import { waitForThirdPartyModule } from './utils/appHelpers';
import initAmplitude from './initAmplitude';
import store from './redux_store/store';
import { AnalyticsService } from './services/AnalyticsService';
import { GTagService, initTagManager } from 'services/GTagService';

export const cookieTypes = {
  FIRST_PARTY: 'C0003',
  THIRD_PARTY: 'C0002',
};

export const cookiesAreEnabled = async (type) => {
  await waitForThirdPartyModule('OnetrustActiveGroups', (val) => !!val.replace(/,/g, ''));
  return window.OnetrustActiveGroups.includes(type);
};

const checkAndInit = async () => {
  await waitForThirdPartyModule('OnetrustActiveGroups');
  initTagManager();
  GTagService.config();
  initAmplitude();
  const { account } = store.getState();
  AnalyticsService.pushUser(account);
};

const initOneTrust = async () => {
  checkAndInit();
  await waitForThirdPartyModule('Optanon');
  window.Optanon.OnConsentChanged(checkAndInit);
};

export default initOneTrust;
