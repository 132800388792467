import Button from '../buttons/Button';
import classNames from 'classnames';
import InfoCircleOutlined from 'shared/components/icons/InfoCircleOutlined';
import CheckmarkOutlined from 'shared/components/icons/CheckmarkOutlined';
import DismissFilled from 'shared/components/icons/DismissFilled';
import ExclamationTriangleOutlined from 'shared/components/icons/ExclamationTriangleOutlined';

const iconsByType = {
  primary: InfoCircleOutlined,
  success: CheckmarkOutlined,
  warning: ExclamationTriangleOutlined,
  danger: ExclamationTriangleOutlined,
};

const Alert = ({
  children,
  size = 'md',
  type = 'primary',
  icon,
  showIcon = true,
  showDismiss = false,
  onClose = () => {},
  className,
}) => {
  const Icon = icon ?? iconsByType[type];

  const classes = classNames(`alert-box alert-box-${type} alert-box-${size}`, className);
  return (
    <div className={classes}>
      <div className='flex-fill d-flex align-items-center'>
        {showIcon && <Icon className='alert-icon' />}
        {children}
      </div>
      {showDismiss && <Button className='alert-close' icon={DismissFilled} onClick={onClose} />}
    </div>
  );
};

export default Alert;
