import { cookiesAreEnabled, cookieTypes } from './../initOneTrust';
import { hotjar as HJ } from 'react-hotjar';

export const HotjarService = {
  init: function (hjid, hjsv) {
    HJ.initialize(hjid, hjsv);
  },
  isInitialized: async function () {
    const enabled = await cookiesAreEnabled(cookieTypes.THIRD_PARTY);
    const initialized = HJ.initialized();
    return enabled && initialized;
  },
  setUser: async function (userId, props = {}) {
    const isReady = await this.isInitialized();
    if (isReady) HJ.identify(userId, props);
  },
  event: async function (event) {
    const isReady = await this.isInitialized();
    if (isReady) HJ.event(event);
  },
};
