import isEmpty from 'lodash/isEmpty';
import { Experiment } from '@amplitude/experiment-js-client';

import { ACCESS_TOKENS, getENV, HOST } from 'configs';
const env = getENV();

class AmplitudeABTestingService {
  init() {
    if (this.experiment) return;

    const options = {};
    if (env !== 'plive') {
      options.serverUrl = `${HOST.API.URL}/api/web-experiments`;
    }
    this.experiment = Experiment.initializeWithAmplitudeAnalytics(
      ACCESS_TOKENS.AMPLITUDE_DEPLOYMENT_KEY,
      options,
    );
  }

  async getVariant(flagKey) {
    if (!this.experiment) {
      this.init();
    }
    const variant = this.experiment.variant(flagKey);
    if (isEmpty(variant)) {
      await this.experiment.fetch();
      return this.experiment.variant(flagKey);
    } else {
      return variant;
    }
  }
}

const amplitudeABTestingService = new AmplitudeABTestingService();

export default amplitudeABTestingService;
