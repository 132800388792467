import { createReducer } from 'utils/reduxHelpers';
import { PLAYER, AUTH } from 'redux_store/actionTypes';

const initialState = 0;

const handlers = {
  [PLAYER.SET_CURRENT_TIME]: (_, action) => action.payload,
  [PLAYER.RESET_CURRENT_TIME]: () => initialState,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
