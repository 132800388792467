export const RedditService = {
  trackEvent: function (eventName) {
    if (!window.rdt) return;
    try {
      window.rdt('track', eventName);
    } catch (err) {
      console.log('RedditService:trackEvent', err);
    }
  },
};
