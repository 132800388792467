import isObject from 'lodash/isObject';

// Load Consts
import { reg } from 'configs';

export const isEmail = (str) => !!(str && reg.email.test(str));
export const isMatchingPasswords = (p1, p2) => !!(p1 && p2 && p1 === p2);
export const isZipCode = (str) => !!(str && reg.zipCode.test(str));

export const isValidName = (name) => {
  const re = /^[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF \w.-]{1,30}$/;
  return re.test(String(name));
};

export const validateForm = (form, errorTypeString) => {
  if (!isObject(form)) {
    throw new TypeError('The property must be an object');
  }

  let errors = {};
  let isValid = true;

  for (let key in form) {
    const { condition, message } = form[key];
    if (!condition) {
      errors = { ...errors, [key]: message };
      isValid = false;
    }
  }

  if (errorTypeString) {
    errors = Object.values(errors).join('\n');
  }

  return { isValid, errors };
};
