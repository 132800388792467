// Load Components
import ToastWrapper from 'shared/components/vendor/ToastWrapper';

export const snackbarSettings = {
  maxSnack: 5,
  Components: {
    default: ToastWrapper,
    success: ToastWrapper,
    info: ToastWrapper,
    warning: ToastWrapper,
    error: ToastWrapper,
  },
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  autoHideDuration: 8000,
  preventDuplicate: true,
  // dense: true,
};

export const tooltipSettings = {
  arrow: true,
  placement: 'bottom',
  title: 'Custom tooltip',
  enterDelay: 500,
  leaveDelay: 200,
  // interactive: true,
};

export const modalSettings = {
  title: 'Confirmation Dialog',
  titleWarning: false,
  hideTitle: false,
  hideHeader: false,
  hideFooter: false,
  hideCancelBtn: false,
  hideSubmitBtn: false,
  hideCloseBtn: false,
  cancelBtnText: 'Close',
  cancelBtnClass: 'text-primary',
  cancelBtnIcon: null,
  cancelBtnDisabled: false,
  cancelBtnIconPosition: 'left',
  submitBtnIconPosition: 'left',
  submitBtnText: 'Submit',
  submitBtnClass: 'primary',
  submitBtnIcon: null,
  submitBtnDisabled: false,
  shouldCloseOnOverlayClick: true,
  buttonsReverse: false,
  footerAlignment: 'center',
  cancelBtnTextClass: '',
};

export const confirmModalSettings = {
  title: 'Confirmation Alert',
  cancelBtnText: 'Cancel',
  submitBtnText: 'Confirm',
};
