import { lazy, useEffect } from 'react';

// Load Vendors
import { Routes, Route } from 'react-router-dom';

// Load HOCs
import withUserActions from 'shared/HOCs/withUserActions';

// Load Hooks
import useRoutingListener from 'shared/hooks/useRoutingListener';
import useExternalError from 'shared/hooks/useExternalError';

// Load Components
import AppLoading from 'shared/components/loaders/AppLoading';

import { getENV } from 'configs/host';
import { StorageService } from 'services/StorageService';
import { useSelector } from 'react-redux';
import { Api } from 'utils/connectors';
import { generateRid, getQueryParam } from 'utils/appHelpers';
import { SocketEventGroups, SocketEvents, useSocket } from 'providers/SocketProvider';
import { compose } from 'redux';

// Load Routes
const UI = lazy(() => import('./components/UI'));
const OAuth2Authorize = lazy(() => import('./routes/Auth/routes/OAuth2Authorize'));
const OAuth2AuthorizeExternal = lazy(() => import('./routes/Auth/routes/OAuth2AuthorizeExternal'));
const RecordView = lazy(() => import('./routes/Dashboard/routes/Recordings/routes/RecordView'));
const Auth = lazy(() => import('./routes/Auth'));
const Gateway = lazy(() => import('./routes/Gateway'));
const Dashboard = lazy(() => import('./routes/Dashboard'));
const ShareCallSummary = lazy(() => import('./routes/Dashboard/routes/ShareCallSummary'));
const MeetingNotesView = lazy(() => import('./routes/Dashboard/routes/MeetingNotesView'));
const AgendaView = lazy(() => import('./routes/Dashboard/routes/AgendaView'));
const MeetingTranscriptView = lazy(() => import('./routes/Dashboard/routes/MeetingTranscriptView'));
const MeetingRequestAccessView = lazy(
  () => import('./routes/Dashboard/routes/MeetingRequestAccessView'),
);

const env = getENV();

const App = ({ authState, initAuthState, handleStorageUpdates, handleRequestFailInterceptor }) => {
  const socket = useSocket();
  const teamId = useSelector((state) => state.account?.team?.id);
  const couponCode = getQueryParam('tr_coupon_code');
  const token = StorageService.get('token');

  if (couponCode) {
    StorageService.set('coupon_code', couponCode);
  }

  useRoutingListener();
  useExternalError();

  useEffect(() => {
    StorageService.onStorageUpdate(handleStorageUpdates);
    initAuthState();
    Api.interceptors.response.use((response) => response, handleRequestFailInterceptor);
  }, []);

  // connect to socket
  useEffect(() => {
    if (token && teamId) {
      // if either token or teamId changes, drop connection and reconnect
      if (socket.connected) socket.disconnect(token);
      socket.connect(token);
      return () => socket.disconnect();
    }
  }, [token, teamId]);

  useEffect(() => {
    if (socket.connected) {
      socket.emitWithAckEvent(SocketEventGroups.SUBSCRIBE, {
        req_id: generateRid(),
        data: {
          events: [
            SocketEvents.SYNC_WEB_PROFILE,
            SocketEvents.SYNC_CUSTOM_VOCABULARY,
            SocketEvents.SYNC_ACTIVITY_EVENT,
            SocketEvents.SYNC_ACTIVITY_COUNTER,
            SocketEvents.SYNC_CALENDAR,
            SocketEvents.SYNC_MEETING,
            SocketEvents.APP_SIGN_IN,
          ],
        },
      });
    }
  }, [socket.connected]);

  if (authState === null) return <AppLoading />;

  const appComponentsByAuthState = {
    0: Auth,
    1: Gateway,
    2: Dashboard,
  };

  const AppComponent = appComponentsByAuthState[authState];

  return (
    <Routes>
      {env !== 'prod' && <Route path='ui' element={<UI />} />}
      <Route path='share/record/:id' element={<RecordView />} />
      <Route path='call-summary/:code' element={<ShareCallSummary />} />
      {authState && <Route path='oauth2/authorize/:id' element={<OAuth2AuthorizeExternal />} />}
      {authState && <Route path='oauth2/authorize' element={<OAuth2Authorize />} />}
      {!authState && <Route path='n/:id' element={<MeetingNotesView />} />}
      {!authState && <Route path='t/:id' element={<MeetingTranscriptView />} />}
      <Route path='meeting-access' element={<MeetingRequestAccessView />} />
      {!authState && <Route path='a/:id' element={<AgendaView />} />}
      {!authState && <Route path='agenda/:id' element={<AgendaView />} />}
      <Route path='/*' element={<AppComponent />} />
    </Routes>
  );
};

export default compose(withUserActions)(App);
