import cn from 'classnames';

const Skeleton = ({ variant = 'rect', width, height, size, className }) => (
  <div
    className={cn('skeleton', className, { [variant]: variant })}
    style={{
      width: width || '100%',
      height: height || (variant === 'circle' ? size : height),
    }}
  />
);

export default Skeleton;
