import { Api } from './connectors';
import { getENV } from 'configs/host';
import { CookieService } from 'services/CookieService';

let customerMethodCalled = false;

export const getRefSourceCookie = () => {
  return CookieService.get('ref_source_affiliate') || undefined;
};

export const removeRefSourceCookie = () => {
  if (getRefSourceCookie()) CookieService.remove('ref_source_affiliate');
};

export const tapfiliateTrack = async (user_id) => {
  const env = getENV();
  if (!user_id || customerMethodCalled) return;

  const getTrackId = () => {
    return new Promise((resolve) => window.tap('getTrackingId', null, resolve));
  };

  const tapCustomer = () => {
    return new Promise((resolve, reject) => {
      window.tap('customer', `${env}-u${user_id}`, { always_callback: true }, (err, data) => {
        customerMethodCalled = true;
        data ? resolve(data) : reject(err);
      });
    });
  };

  try {
    const trackingId = await getTrackId();
    const customer = await tapCustomer();

    if (customer) {
      await Api.post('/afiliate/attach', {
        customer_id: customer.customer_id,
        tracking_id: trackingId,
        meta: {
          program: customer.program.id,
          affiliate_id: customer.affiliate.id,
        },
      });
    }
  } catch (err) {
    console.warn(err);
  }
};
