import classNames from 'classnames';

const Button = ({
  type,
  apperance,
  size,
  icon,
  iconPosition = 'left',
  iconClass,
  block,
  rounded,
  className,
  disabled,
  children,
  onClick,
  dataTestId,
  textClass = '',
}) => {
  const Icon = icon;
  const classes = classNames('btn', `btn-${apperance}`, className, {
    [`btn-${size}`]: size,
    'btn-rounded': rounded,
    'btn-icon': icon && !children,
    'btn-block': block,
    'no-events': disabled,
  });
  return (
    <button
      type={type}
      className={classes}
      onClick={onClick}
      disabled={disabled}
      data-test-id={dataTestId}
    >
      {Icon && iconPosition === 'left' && <Icon className={iconClass} />}
      {children && <span className={textClass}>{children}</span>}
      {Icon && iconPosition === 'right' && <Icon className={iconClass} />}
    </button>
  );
};

export default Button;
