// Load Apis
import { Api } from 'utils/connectors';

// Load Services
import { AnalyticsService } from 'services/AnalyticsService';

// Load Vendors
import store from 'redux_store/store';

// Load Actions
import UserActions from 'redux_store/actions/userActions';

const isBooleanRepresentation = ({ boolean_representation }) => {
  if (!boolean_representation) return false;
  return Object.keys(boolean_representation).sort().toString() === 'false,true';
};

export const getPreferenceBooleanValue = (preference) => {
  if (!preference) return null;
  if (preference.type === 'boolean') return preference.value;
  const { value, boolean_representation } = preference;
  if (!boolean_representation || !isBooleanRepresentation({ boolean_representation })) return null;
  const boolString =
    Object.keys(boolean_representation)[Object.values(boolean_representation).indexOf(value)];
  switch (boolString) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
};

export const convertBooleanValueToString = (boolean, preference) => {
  if (preference.type === 'boolean') return boolean;
  const { boolean_representation } = preference;
  if (!boolean_representation || !isBooleanRepresentation({ boolean_representation })) return null;
  return Object.values(boolean_representation)[
    Object.keys(boolean_representation).indexOf(String(boolean))
  ];
};

export const bulkUpdatePreferences = async (preferencesToUpdate, branch) => {
  const entriesToUpdate = Object.entries(preferencesToUpdate);

  if (entriesToUpdate.length) {
    await Api.put(`/preferences/${branch}/self`, preferencesToUpdate);

    entriesToUpdate.forEach(([path, value]) => {
      AnalyticsService.sendEvent(AnalyticsService.events.fn_update_preference, {
        data: { path, value },
      });
    });

    const pathPrefMap = entriesToUpdate.reduce((payload, [path, value]) => {
      const dotPath = `${path.replace('preference#', '').replaceAll('#', '.')}.value`;
      payload[dotPath] = value;
      return payload;
    }, {});

    store.dispatch(
      UserActions.updatePreferencesPartially({
        branch,
        preferences: pathPrefMap,
      }),
    );

    if (branch !== 'profile') {
      store.dispatch(
        UserActions.updatePreferencesPartially({
          branch: 'profile',
          preferences: pathPrefMap,
        }),
      );
    }
  }
};
