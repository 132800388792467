import { PermissionService } from 'services/PermissionService';
import store from 'redux_store/store';

const { hasAccess } = PermissionService;

export const openZenDeskChat = ({ url, name }, e) => {
  e?.preventDefault();
  if (name === 'vcube' || !window.zE) {
    window.open(url, '_blank');
  } else {
    window.zE('webWidget', 'open');
  }
};

export const closeZenDeskChat = () => {
  if (!window.zE) return;
  window.zE('webWidget', 'close');
};

export const toggleZenDeskChat = () => {
  if (!window.zE) return;

  const resources = store.getState().resources;
  const isUserVcube = resources?.chat?.name === 'vcube';

  // This is for zendesk messaging form
  // const toggle = isUserVcube ? -1 : 99999;
  // window.zE('messenger:set', 'zIndex', toggle);

  // This is for zendesk ticketing form
  const toggle = isUserVcube ? 'hide' : 'show';
  window.zE('webWidget', toggle);
};

export const zenDeskSetInitialState = () => {
  toggleZenDeskChat();
};

export const setZenDeskChatData = (account) => {
  if (!account || !window.zE) return;
  // Email is needed only for zendesk ticketing form.
  const { email, team } = account;

  // We need only team for zendesk messaging form
  // const { team } = account;
  const tags = [];

  tags.push('app.krisp.ai');

  if (hasAccess('user_appsumo')) tags.push('appsumo_user');
  if (hasAccess('team_admin')) tags.push('admin');
  if (hasAccess('user_vcube')) tags.push('vcube');
  if (hasAccess('team_subscription_invoice')) tags.push('invoice_payment');
  if (hasAccess('team_subscription_card')) tags.push('card_on_file');
  tags.push(hasAccess('user_app_used') ? 'app_used' : 'app_not_used');
  if (team && team.trial) {
    tags.push('team_trial');
  } else if (!hasAccess('team_free')) {
    tags.push('paid');
  } else {
    tags.push('free');
  }

  // This part is for zendesk ticketing form.
  try {
    window.zE('webWidget', 'updateSettings', {
      contactForm: {
        tags,
        fields: [{ id: 'email', prefill: { '*': email } }],
      },
    });
  } catch (e) {
    console.error(e);
  }
};
