import Portal from './Portal';
import { Tooltip as TooltipV2 } from '@krispai/kr-ui-components';
import { v4 as uuidv4 } from 'uuid';

const Tooltip = ({ children, place = 'top', tooltipContent, className = '', ...tooltipProps }) => {
  const id = uuidv4();

  return (
    <>
      <Portal>
        <TooltipV2 id={id} place={place} content={tooltipContent} {...tooltipProps} />
      </Portal>
      <div className={`d-inline-flex align-items-center ${className}`} data-tooltip-id={id}>
        {children}
      </div>
    </>
  );
};

export default Tooltip;
