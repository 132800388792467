import { createReducer } from 'utils/reduxHelpers';
import { USER } from 'redux_store/actionTypes';

const initialState = null;

const handlers = {
  [USER.SET_INSTALLATION_META]: (state, action) => action.payload,
};

export default createReducer(initialState, handlers);
