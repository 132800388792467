import { BILLING } from '../actionTypes';

const setPlans = (payload) => ({ type: BILLING.SET_PLANS, payload });
const setInvoices = (payload) => ({ type: BILLING.SET_INVOICES, payload });
const setTeamStatus = (payload) => ({ type: BILLING.SET_TEAM_STATUS, payload });
const removeInvoices = () => ({ type: BILLING.REMOVE_INVOICES });
const setBilling = (payload) => ({ type: BILLING.SET_BILLING, payload });
const removeBilling = () => ({ type: BILLING.REMOVE_BILLING });
const updateUnassignedSeatCount = (payload) => ({
  type: BILLING.UPDATE_UNASSIGNED_SEATS_COUNT,
  payload,
});

const actions = {
  setPlans,
  setInvoices,
  setTeamStatus,
  removeInvoices,
  setBilling,
  removeBilling,
  updateUnassignedSeatCount,
};

export default actions;
