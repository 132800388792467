import { PermissionService } from 'services/PermissionService';

export const getCalendarPermissions = (oauthScopes) => {
  const permissions = [];
  if (oauthScopes?.events_read || oauthScopes?.calendar_read) permissions.push('calendar_events');
  if (oauthScopes?.contacts_read) permissions.push('calendar_contacts');
  if (oauthScopes?.directory_read) permissions.push('calendar_directory');
  if (oauthScopes?.calendar_read) permissions.push('calendar_read');
  if (
    oauthScopes?.provider &&
    (oauthScopes?.events_read ||
      oauthScopes?.contacts_read ||
      oauthScopes?.directory_read ||
      oauthScopes?.calendar_read)
  ) {
    permissions.push('calendar');
  }
  return permissions;
};

export const hasCalendarFullAccess = (provider) => {
  return (
    provider === 'microsoft' ||
    (PermissionService.hasAccess('calendar_events') &&
      PermissionService.hasAccess('calendar_contacts') &&
      PermissionService.hasAccess('calendar_directory') &&
      PermissionService.hasAccess('calendar_read'))
  );
};
