// Load Services
import { AnalyticsService } from 'services/AnalyticsService';
import { StorageService } from 'services/StorageService';

// Load Vendors
import cloneDeep from 'lodash/cloneDeep';
import uniqBy from 'lodash/uniqBy';
import { dateFormat } from 'utils/dateHelpers';
import {
  bindDurationHours,
  ordinalSuffix,
  capitalize,
  bindStartTime,
  arrayToString,
  buildNotesToCopy,
  getQueryParam,
  isMobileOrTablet,
} from 'utils/appHelpers';

// Load Consts
import { MeetingNotesFeatures, reg } from 'configs';

export const getWordTimestampsFromTranscript = (transcript) => {
  return transcript.reduce((acc, speaker) => {
    if (speaker.speech.wordStartTime) {
      speaker.speech.wordStartTime.forEach((time) => {
        const key = Number(time.toFixed(1));
        acc[key] = { time: key };
      });
    }
    return acc;
  }, {});
};

export const getSpeechTimestampsFromTranscript = (transcript) => {
  return transcript.map((v) => ({
    startTime: v.speech.start,
    endTime: v.speech.end,
    speakerIndex: v.speakerIndex,
  }));
};

export const findNearestSpeechTime = (wordTimestamps = {}, currentTime) => {
  let nearestTime = null;
  let minDifference = Infinity;

  for (const key in wordTimestamps) {
    const timestamp = wordTimestamps[key].time;
    const difference = Math.abs(timestamp - currentTime);

    if (difference < minDifference) {
      minDifference = difference;
      nearestTime = timestamp;
    }
  }

  return nearestTime;
};

export const findTalkingSpeaker = (speechTimestamps = [], currentTime) => {
  let speaker = null;
  speechTimestamps.forEach((item) => {
    if (item.startTime <= currentTime && item.endTime > currentTime) {
      speaker = item;
    }
  });
  return speaker;
};

export const findPreviousSpeechTime = (speechTimestamps = [], currentTime) => {
  let previousTime = null;
  speechTimestamps.forEach((speaker) => {
    if (speaker.endTime < currentTime) previousTime = speaker;
  });
  return previousTime;
};

export const findNextSpeechTime = (speechTimestamps = [], currentTime, speakerIndex = null) => {
  return speechTimestamps.find(({ startTime, speakerIndex: idx }) => {
    return startTime > currentTime && (speakerIndex === null || idx === speakerIndex);
  });
};

export const constructEditedTranscriptRowContent = (text, speechStart = -1, speechEnd = -1) => {
  // Note: splitting only with 1 '\n' to cover also cases when there are multiple sequential '\n' in the text
  const dividedText = text.split('\n');
  return dividedText.flatMap((word, i) => {
    if (word === '') return { word: '\n', timestamp: -1, speechStart, speechEnd };
    const result = [{ word, timestamp: -1, speechStart, speechEnd }];
    // Add '\n' after the word if the next word is empty
    // (to keep the same structure as in the original transcript)
    if (i !== dividedText.length - 1 || dividedText[i + 1] === '') {
      result.push({ word: '\n', timestamp: -1, speechStart, speechEnd });
    }
    return result;
  });
};

export const highlightTranscriptWords = (transcript, vocabulary = {}, highlightVocabWords) => {
  const lowerCaseVocabWords = vocabulary.rows?.map((w) => w.value.toLowerCase());
  const vocabMatchingWords = new Set();
  const vocabLimitReached = vocabulary.rows?.length >= vocabulary.limit;

  const lcwhEnabled = MeetingNotesFeatures.transcript.lowConfidenceWordsHighlight.enabled;
  const minConfidence = MeetingNotesFeatures.transcript.lowConfidenceWordsHighlight.minConfidence;

  const isMobile = isMobileOrTablet();
  let isFirstLowConfidenceWordSet = StorageService.get('low_confidence_tooltip_shown') || false;

  const _wrap = (word, flag) => `{{${word}}}[${flag}]`;

  const _highlightWords = ({ text, confidence, wordStartTime, start, end, is_edited }) => {
    const speechStart = Number((wordStartTime?.[0] || start).toFixed(1));
    const speechEnd = Number(end.toFixed(1));

    if (is_edited) {
      return constructEditedTranscriptRowContent(text, speechStart, speechEnd);
    }

    const _highlightWord = (word, restProps, i) => {
      const cleanWord = word.match(reg.cleanWord)?.[0] || word;
      // Detecting words from custom vocab
      if (highlightVocabWords && lowerCaseVocabWords?.includes(cleanWord.toLowerCase())) {
        vocabMatchingWords.add(word);
      }

      if (highlightVocabWords && lcwhEnabled) {
        if (
          confidence?.[i] <= minConfidence &&
          !vocabLimitReached &&
          !lowerCaseVocabWords.includes(cleanWord.toLowerCase())
        ) {
          isFirstLowConfidenceWordSet = true;
          return [
            {
              word: _wrap(
                word,
                `low-confidence-highlight${
                  !isMobile && !isFirstLowConfidenceWordSet ? '.with-tooltip' : ''
                }`,
              ),
              ...restProps,
            },
          ];
        }
      }

      if (word.includes('[Laughter]')) {
        // Separate '[Laughter]' from other connected elements example: `Don't\n\n[Laughter]`
        const matches = word.match(/(\[Laughter\])|([^[\]]+(?=\[Laughter\])|[^[\]]+$)/g);
        return matches.map((match) =>
          match === '[Laughter]'
            ? { word: _wrap(match, 'laughter-highlight'), ...restProps }
            : { word: match, ...restProps },
        );
      }

      return [{ word: word, ...restProps }];
    };

    const textChunks = [];
    const words = text
      .replace(/<[^>]*>/g, '')
      .split(' ')
      .filter(Boolean);
    words.map((word, i) => {
      const restProps = {
        timestamp: Number(wordStartTime?.[i]?.toFixed(1) || start),
        speechStart,
        speechEnd,
      };

      word
        .replaceAll('\n', ' \n ')
        .split(' ')
        .filter(Boolean)
        .forEach((pieceOfWord) => {
          const modifiedPieces = _highlightWord(pieceOfWord, restProps, i);
          textChunks.push(...modifiedPieces);
        });
    });
    return textChunks;
  };

  const transcriptWithHighlightedWords = transcript.map((item) => {
    item.speech.text_chunks = _highlightWords(item.speech);
    return item;
  });

  AnalyticsService.sendEvent(AnalyticsService.events.fn_word_detected_from_custom_vocab, {
    data: {
      count: vocabMatchingWords.size,
    },
    status: 'success',
  });

  return transcriptWithHighlightedWords;
};

export const getUserShortName = (firstName = '', lastName = '') => {
  const firstInitial = firstName?.charAt(0).toUpperCase() || '';
  const lastInitial = lastName?.charAt(0).toUpperCase() || '';
  return `${firstInitial}${lastInitial}`;
};

export const getPersonInitials = (person = {}, options = {}) => {
  person = person || {};
  let fname = person.first_name?.trim() || '';
  let lname = person.last_name?.trim() || '';
  if (lname && options.shortenLastName) lname = `${lname.charAt(0)}.`;
  return `${fname} ${lname}`.trim() || person.email;
};

export const getParticipantName = (person, speakerIndex, hasEditAccess = false) => {
  const hasPerson = person?.first_name || person?.email;
  if (hasPerson) return getPersonInitials(person, { shortenLastName: true });
  if (person?.self && hasEditAccess) return person?.first_name || 'You';
  return getSpeakerIndexAsSpeakerName(speakerIndex);
};

export const getParticipantFullName = (person, speakerIndex) => {
  const hasPerson = person?.first_name || person?.email;
  const personName =
    person?.first_name && `${person.first_name || ''} ${person?.last_name || ''}`.trim();

  if (person?.self) return personName || 'You';
  if (hasPerson) return getPersonInitials(person);
  return getSpeakerIndexAsSpeakerName(speakerIndex);
};

export const getSpeakerIndexAsSpeakerName = (speakerIndex) => {
  return `Speaker ${speakerIndex}`;
};

export const setUppedSpeakerCount = (speakers) => {
  const setSpeakers = Object.keys(speakers).filter((key) => {
    const person = speakers[key]?.person;
    return person?.first_name || person?.email;
  });

  return setSpeakers.length;
};

export const checkSpeakersAssignment = (data = [], speakers = {}) => {
  const uniqSpeakers = new Set();

  data.forEach(({ speakerIndex, self }) => {
    if (!uniqSpeakers.has(speakerIndex) && !self) uniqSpeakers.add(speakerIndex);
  });

  return {
    uniqueSpeakersCount: uniqSpeakers.size,
    assignedSpeakersCount: setUppedSpeakerCount(speakers),
    isAllSpeakerSet: uniqSpeakers.size === setUppedSpeakerCount(speakers),
  };
};

export const getRepetitiveNamesInfo = (data, speakers = {}) => {
  if (!data || !setUppedSpeakerCount(speakers)) return;
  const speakerIndexes = [];
  const uniquePerson = [];
  const repetitiveNamesInfo = {};
  for (const speakerData of data) {
    const person = speakers[speakerData.speakerIndex]?.person || {};
    const firstName = person?.first_name?.trim();
    const lastName = person?.last_name?.trim();
    if (
      !firstName ||
      speakerIndexes.includes(speakerData.speakerIndex) ||
      (person.id && uniquePerson.includes(person.id))
    )
      continue;

    speakerIndexes.push(speakerData.speakerIndex);
    uniquePerson.push(person?.id);

    if (firstName && !lastName) {
      if (firstName in repetitiveNamesInfo) {
        repetitiveNamesInfo[firstName].hasFirstNameDuplicate = true;
        continue;
      }

      repetitiveNamesInfo[firstName] = repetitiveNamesInfo[firstName] || {
        partNames: [],
      };
      continue;
    }

    if (!(firstName in repetitiveNamesInfo)) {
      const partName = `${firstName} ${lastName.charAt(0)}`;
      repetitiveNamesInfo[firstName] = { partNames: [partName] };
      continue;
    }

    repetitiveNamesInfo[firstName].hasFirstNameDuplicate = true;

    const partName = `${firstName} ${lastName.charAt(0)}`;
    if (repetitiveNamesInfo[firstName].partNames.includes(partName)) {
      // use full name if first name and first char of last name is repetitive
      repetitiveNamesInfo[firstName].hasPartNameDuplicate = true;
      continue;
    }
    repetitiveNamesInfo[firstName].partNames.push(partName);
  }
  return repetitiveNamesInfo;
};

export const getShorterName = (
  repetitiveNamesInfo,
  person,
  speakerIndex,
  hasEditAccess = false,
) => {
  const firstName = person?.first_name?.trim();
  const lastName = person?.last_name?.trim();
  if (!repetitiveNamesInfo || !(repetitiveNamesInfo[firstName] || lastName || person?.email))
    return getParticipantName(person, speakerIndex, hasEditAccess);

  if (firstName && lastName && repetitiveNamesInfo[firstName]?.hasPartNameDuplicate) {
    return `${firstName} ${lastName}`;
  }
  if (firstName && lastName && repetitiveNamesInfo[firstName]?.hasFirstNameDuplicate) {
    return `${firstName} ${lastName.charAt(0)}.`;
  }
  if (firstName) {
    return firstName;
  }
  if (lastName) {
    return lastName;
  }
  if (person?.email) {
    return person.email;
  }
  return getParticipantName(person, speakerIndex, hasEditAccess);
};

export const getParticipantImageURL = (person) => {
  return person ? person.photo : null;
};

export const getUniqueSpeakersCount = (transcript = []) => {
  const speakersObj = transcript.reduce((acc, { speakerIndex }) => {
    return { ...acc, [speakerIndex]: true };
  }, {});
  return Object.keys(speakersObj).length;
};

export const resetTranscript = (transcript = []) => {
  // eslint-disable-next-line no-unused-vars
  return cloneDeep(transcript).map(({ person, ...rest }) => rest);
};

export const getTranscriptURIFromNotesURI = () => {
  const newURI = window.location.pathname.replace(/\/n\//, '/t/');
  return newURI;
};

export const getMeetingDateInfo = (date, duration) => {
  const _setDur = (v, unit) => (v ? `${v}${unit}` : '');
  const { hours, minutes } = bindDurationHours(duration);
  const formattedDate = dateFormat(date, 'DD MMM');
  const formattedStartTime = dateFormat(date, 'hh:mm A');
  const formattedEndTime = dateFormat(date, 'hh:mm A');
  const formattedDuration = `${_setDur(hours, 'h')} ${_setDur(minutes, 'm')}`.trim() || '0 mins';
  return {
    formattedDate,
    formattedStartTime,
    formattedEndTime,
    formattedDuration,
  };
};

export const updateWordsAndUpdateConfidence = (
  content,
  oldWord,
  newWord,
  minConfidence = 0.3,
  maxConfidence = 1,
) => {
  const updatedContent = content.map((speaker) => {
    const words = speaker.speech.text.split(' ');
    speaker.speech.text = words
      .map((word, i) => {
        const firstPunctuation = word.match(/^\p{P}+/gu)?.[0] || '';
        const lastPunctuation = word.match(/\p{P}+$/gu)?.[0] || '';
        const cleanCurrentWord = word.match(/[\p{L}-]+/gu)?.[0] || word;
        const cleanOldWord = oldWord.match(/[\p{L}-]+/gu)?.[0] || oldWord;
        if (cleanCurrentWord === cleanOldWord && speaker.speech.confidence[i] <= minConfidence) {
          speaker.speech.confidence[i] = maxConfidence;
          return `${firstPunctuation}${newWord}${lastPunctuation}`;
        }
        return word;
      })
      .join(' ');
    return speaker;
  });
  return updatedContent;
};

export const getAttendeeCredentials = (attendee) => {
  if (!attendee) return '';
  return attendee.first_name
    ? `${attendee.first_name} ${attendee.last_name || ''}`.trim()
    : attendee.email;
};

export const isMatchingAttendee = (attendee, searchTerm) => {
  if (!attendee) return false;
  const attendeeCredentials = getAttendeeCredentials(attendee);
  return attendeeCredentials?.toLowerCase() === searchTerm?.toLowerCase();
};

export const findMatchingAttendee = (attendees, searchTerm) => {
  return attendees.find((selAtt) => isMatchingAttendee(selAtt, searchTerm));
};

export const sortMeetingAttendees = (
  attendees = [],
  selectedAttendees,
  search = '',
  assignedSpeakerId,
  selectedPerson,
) => {
  assignedSpeakerId = assignedSpeakerId || selectedPerson?.id;
  const contactsWithEmail = attendees.filter((el) => el.email);
  const customOnes = attendees.filter((el) => el.id && !el.email);
  const assignedPerson = attendees.find((el) => el.id === assignedSpeakerId);
  const unifiedAttendees = uniqBy(contactsWithEmail, 'email')
    .concat(uniqBy(customOnes, 'id'))
    .filter((el) => el.id !== assignedSpeakerId);

  const _match = (s = '') => s.match(new RegExp(search, 'gi'));
  const sorted = unifiedAttendees.sort((a, b) => {
    const fullNameA = `${a.first_name?.trim()} ${a.last_name?.trim()}`;
    const fullNameB = `${b.first_name?.trim()} ${b.last_name?.trim()}`;
    const emailMatchA = _match(a.email) !== null;
    const emailMatchB = _match(b.email) !== null;

    // Prioritize attendees in selectedAttendees
    const isSelectedA = selectedAttendees.some((selected) => selected.id === a.id);
    const isSelectedB = selectedAttendees.some((selected) => selected.id === b.id);

    // Sort based on full name matches
    if (_match(fullNameA) && !_match(fullNameB)) return -1;
    if (!_match(fullNameA) && _match(fullNameB)) return 1;

    // Sort based on email matches first
    if (emailMatchA && !emailMatchB) return -1;
    if (!emailMatchA && emailMatchB) return 1;

    // Sort based on selectedAttendees
    if (isSelectedA && !isSelectedB) return -1;
    if (!isSelectedA && isSelectedB) return 1;

    // Sort based on ID if both have no email matches or full name matches
    return a.id - b.id;
  });

  if (assignedSpeakerId && assignedPerson) {
    sorted.unshift(assignedPerson);
  }

  return sorted;
};

export const getNewSpeakerIndex = (transcript, speakers, newAttendee, isPartial) => {
  const _findNewIndex = (newAttendee) => {
    const matchingSpeaker = transcript.find(({ speakerIndex }) => {
      const person = speakers[speakerIndex]?.person;
      if (!person || person.self) return;
      if (person.email) {
        return newAttendee.email === person.email;
      } else {
        return (
          newAttendee.first_name === person.first_name &&
          newAttendee.last_name === person.last_name &&
          !person.email
        );
      }
    });
    return matchingSpeaker?.speakerIndex;
  };

  let newSpeakerIndex;
  if (isPartial) {
    const newFoundSpeakerIndex = _findNewIndex(newAttendee);
    if (newFoundSpeakerIndex !== undefined) {
      newSpeakerIndex = newFoundSpeakerIndex;
    }
  }
  return newSpeakerIndex;
};

export const getPresentedAttendees = (speakers, outboundIndex) => {
  const newPresented = [];
  Object.keys(speakers).forEach((si) => {
    const person = speakers[si]?.person;
    if (!person || si === outboundIndex) return;

    const alreadyIncluded = newPresented.find((att) => {
      if (!att) return false;

      if (person.email) {
        return att.email === person.email;
      } else if (person) {
        return (
          att.first_name === person.first_name &&
          att.last_name === person.last_name &&
          !person.email
        );
      }
    });

    if (alreadyIncluded === undefined) {
      newPresented.push({ ...person, speakerIndex: si });
    }
  });

  return newPresented;
};

export const buildTranscriptName = (name = '') => {
  const beautifiedName = (name || 'Transcript').replace(/[^\p{L}\p{N}_.-]+/gu, '-');
  return encodeURIComponent(beautifiedName);
};

export const getNavigationURLs = (title, id, search = '') => {
  const formattedTitle = buildTranscriptName(title);
  const URL = `${formattedTitle}--${id}${search}`;

  return {
    notesPageURL: `/n/${URL}`,
    transcriptPageURL: `/t/${URL}`,
    agendaPageURL: `/a/${URL}`,
  };
};

export const getRecurrenceText = ({ type, week_days, month_days, position, interval }) => {
  if (type === 'daily') {
    if (interval > 1) {
      return `Every ${interval} days`;
    } else {
      return 'Daily';
    }
  } else if (type === 'weekly') {
    const weekDaysText = week_days ? `on ${week_days.map(capitalize).join(', ')}` : ''; // "on Monday, Tuesday"
    if (interval > 2) {
      return `Every ${interval} weeks ${weekDaysText}`; // "Every 3 weeks", "Every 3 weeks on Monday, Tuesday"
    } else if (interval > 1) {
      return `Biweekly ${weekDaysText}`; // "Biweekly", "Biweekly on Monday, Tuesday"
    } else if (week_days?.length === 5) {
      return `Every Weekday`;
    } else {
      return `Weekly ${weekDaysText}`; // "Weekly", "Weekly on Monday, Tuesday"
    }
  } else if (type === 'monthly') {
    const positionText = position ? `${position} ` : ''; // "first", "second", "third", "fourth", "last"

    const monthDaysText = month_days
      ? `on ${positionText}${month_days.map(ordinalSuffix).join(' and ')} ${
          month_days?.length === 1 ? 'day' : 'days'
        }`
      : ''; // "on 1st and 15th day", "on 15th day"
    if (positionText && week_days?.length > 0) {
      const weekDaysText = `on ${positionText}${week_days.map(capitalize).join(' and ')}`;
      if (interval > 1) {
        return `Every ${interval} months ${weekDaysText}`; // "Every 3 months on first Monday and Tuesday", "Every 3 months on first Monday"
      } else {
        return `Monthly ${weekDaysText}`; // "Monthly on first Monday and Tuesday", "Monthly on first Monday"
      }
    } else if (interval > 1) {
      return `Every ${interval} months ${monthDaysText}`; // "Every 3 months on 1st and 15th day", "Every 3 months on 15th day", "Every 3 months"
    } else {
      return `Monthly ${monthDaysText}`; // "Monthly on 1st and 15th day", "Monthly on 15th day", "Monthly"
    }
  } else if (type === 'yearly') {
    if (interval > 1) {
      return `Every ${interval} years`;
    } else {
      return 'Yearly';
    }
  }
};

export const excludeDeletedMeetings = (list, deletedIds) => {
  let validList = list;
  if (deletedIds && deletedIds.length) {
    validList = list.filter((row) => !deletedIds.includes(row.id));
  }
  return { filteredList: validList, countDiff: list.length - validList.length };
};

export const showRatingBoxAfterLetters = (limit) => {
  let totalLettersCount = 0;
  let isLimitReached = false;
  let hasBeenShown = false;

  return (lettersCount, isLastItem) => {
    if (isLastItem && !hasBeenShown) return true;

    totalLettersCount += lettersCount;
    isLimitReached = totalLettersCount > limit;

    if (isLimitReached && !hasBeenShown) {
      hasBeenShown = true;
      return true;
    }
    return false;
  };
};

export const getSpeechRanges = (transcript = []) => {
  return transcript.reduce((acc, item) => {
    const start = item.speech.wordStartTime?.[0] || 0; // (Hayk) sometimes speech.start isn't matching with wordStartTime[0] because of filler words removal with their speech times from the wordStartTime
    const end = item.speech.end || 0;
    acc.push([Number(start.toFixed(1)), Number(end.toFixed(1))]);
    return acc;
  }, []);
};

export const findMatchingRange = (ranges = [], value = 0) => {
  return ranges.find((range) => {
    const [start, end] = range;
    if (value >= start && value <= end) return range;
    return false;
  });
};

// It modifies each session by adding actual start and end times for audio file
export const modifyRecordingSessions = (sessions = []) => {
  const updatedSessions = [];

  sessions.reduce((acc, session) => {
    const playerTimeEnd = session.end - session.start + acc;
    const playerTimeStart = playerTimeEnd - (session.end - session.start);
    updatedSessions.push({ ...session, playerTimeEnd, playerTimeStart });
    return playerTimeEnd;
  }, 0);

  return updatedSessions;
};

export const setMeetingDurationAndFixSpeachItems = (meeting) => {
  const { recording, transcript } = meeting || {};
  const isOnlyRecording = recording && !transcript?.content?.length;
  const isOnlyContent = !recording && !!transcript?.content?.length;
  const isBoth = !!recording && !!transcript?.content?.length;

  let mostMinStart = 0;
  let mostMaxEnd = 0;

  if (isBoth) {
    const recordingFirstSessionStart = recording.sessions?.[0].start;
    const recordingLastSessionEnd = recording.sessions?.[recording.sessions.length - 1].end;
    const speechFirstSessionStart = transcript.content?.[0].speech.start;
    const speechLastSessionEnd = transcript.content?.[transcript.content.length - 1].speech.end;
    mostMinStart = Math.floor(Math.min(recordingFirstSessionStart, speechFirstSessionStart));
    mostMaxEnd = Math.floor(Math.max(recordingLastSessionEnd, speechLastSessionEnd));
  }

  if (isOnlyContent) {
    mostMinStart = transcript.content?.[0].speech.start;
    mostMaxEnd = transcript.content?.[transcript.content.length - 1].speech.end;
  }

  if (isOnlyRecording) {
    mostMinStart = recording.sessions?.[0].start;
    mostMaxEnd = recording.sessions?.[recording.sessions.length - 1].end;
  }

  if (transcript?.content?.length) {
    transcript.content = transcript.content.map((item) => {
      item.speech.start -= mostMinStart;
      item.speech.end -= mostMinStart;
      item.speech.wordStartTime = item.speech.wordStartTime?.map((time) => time - mostMinStart);
      return item;
    });
  }

  if (recording && recording.sessions?.length) {
    recording.sessions = recording.sessions.map((session) => {
      session.start -= mostMinStart;
      session.end -= mostMinStart;
      return session;
    });
  }

  meeting.duration = mostMaxEnd - mostMinStart;

  return meeting;
};

export const getCurrentSessionWithMeetingTime = (sessions, currentTime) => {
  return sessions?.find(
    (session) =>
      Math.round(session.start) <= Math.round(currentTime) &&
      Math.round(currentTime) <= Math.round(session.end),
  );
};

export const getTranscriptTextToCopy = (transcript, speakers) => {
  if (!transcript) return '';
  return transcript.reduce((acc, { speakerIndex, speech }) => {
    let identifier = 'You';
    const person = speakers[speakerIndex]?.person;
    const hasPerson = person?.first_name || person?.email;
    if (hasPerson) {
      identifier =
        `${person.first_name?.trim() || ''} ${person.last_name?.trim() || ''}`.trim() ||
        person.email;
    } else {
      identifier = getSpeakerIndexAsSpeakerName(speakerIndex);
    }
    const speechTime = bindStartTime(speech.speechTime || speech.start) || '00:00';
    const title = `<strong>${identifier} | ${speechTime}\n<br></strong>`;
    const text = speech.text.replaceAll('\n', '\n<br>');
    acc += `${title}${text}\n<br>`;
    return acc;
  }, '');
};

export const getNotesTextToCopy = (meeting) => {
  if (!meeting) return '';

  const { key_points, action_items, next_steps, detailed_summary } = meeting;
  const keyPointsContent = arrayToString(key_points?.content) || '';
  const actionItemsContent = arrayToString(action_items?.content) || '';
  const nextStepsContent = arrayToString(next_steps?.content) || '';
  const detailedSummaryContent = arrayToString(detailed_summary?.content) || '';

  const prepareNotesToCopy = () => {
    let arr = [];
    if (MeetingNotesFeatures.meeting_notes.key_points.enabled && keyPointsContent.length) {
      arr = [...arr, '<strong>Key Points</strong>', keyPointsContent, '\n'];
    }
    if (MeetingNotesFeatures.meeting_notes.action_items.enabled && actionItemsContent.length) {
      arr = [...arr, '<strong>Action Items</strong>', actionItemsContent, '\n'];
    }
    if (MeetingNotesFeatures.meeting_notes.next_steps.enabled && nextStepsContent.length) {
      arr = [...arr, "<strong>What's next</strong>", nextStepsContent];
    }

    if (
      MeetingNotesFeatures.meeting_notes.detailed_summary.enabled &&
      detailedSummaryContent.length
    ) {
      arr = [...arr, '<strong>Summary</strong>', detailedSummaryContent];
    }
    return arr.join('\n<br/>');
  };

  return buildNotesToCopy(prepareNotesToCopy(), meeting);
};

export const getSpeakerIdentifierForPattern = (match, speakers) => {
  const speakerIndex = match.match(/\d+/g)[0];
  const person = speakers[speakerIndex]?.person;
  const hasPersonSet = person?.first_name || person?.email;
  const identifier = getPersonInitials(person);
  const sanitizedSpeakerMatch = match.replace(/\{\{|\}\}/g, ''); // Making {{Speaker_X}} -> Speaker_X
  const speakerIdentifier = hasPersonSet ? identifier : sanitizedSpeakerMatch;
  return speakerIdentifier;
};

export const calcMeetingRecordingStorageSize = (meetings = []) => {
  return meetings.reduce((acc, item) => {
    const recordingSizeSum = item?.resources?.recordings?.length
      ? item.resources.recordings.reduce((acc, recording) => acc + recording.size, 0)
      : 0;
    return acc + recordingSizeSum;
  }, 0);
};

export const getSignupSource = () => {
  const defaultSource = 'default';
  const availableSources = [
    'access_grant_email',
    'recap_email',
    'invitation_email',
    'team_invite',
    'share_link',
  ];
  const source = getQueryParam('tr_utm_source');
  return availableSources.includes(source) ? source : defaultSource;
};

export const constructSearchRegex = (wordToFind, { matchCase, wholeWord }) => {
  const flags = ['g'];
  if (!matchCase) flags.push('i');

  const negativeLookahead = '(?!Speaker_\\d+)';
  let word = wordToFind;
  if (wholeWord) word = '\\b' + word + '\\b';

  return new RegExp(negativeLookahead + word, flags.join(''));
};

export const getAllowedRangesToPlay = (selectedSpeakersToListen, speechTimestamps) => {
  if (selectedSpeakersToListen && speechTimestamps?.length > 0) {
    return speechTimestamps.filter((speech) => selectedSpeakersToListen[speech.speakerIndex]);
  }
};

export const checkCurrentAndNextAllowedRanges = (allowedRanges, time) => {
  if (!allowedRanges?.length || !time) return {};

  const isInAllowedRange = allowedRanges.some(
    (range) => time >= range.startTime && time <= range.endTime,
  );
  let nextAllowedRange = null;
  if (!isInAllowedRange) {
    // find the next closest range
    nextAllowedRange = allowedRanges.find((range) => range.startTime > time);
  }
  return { isInAllowedRange, nextAllowedRange };
};
