import store from 'redux_store/store';
import AuthActions from 'redux_store/actions/authActions';
import UserActions from 'redux_store/actions/userActions';
import { HotjarService } from 'services/HotjarService';
import { setZenDeskChatData } from 'utils/zenDeskHelpers';
import { PermissionService } from 'services/PermissionService';
import { AnalyticsService } from 'services/AnalyticsService';
import * as Sentry from '@sentry/react';
import { Api } from 'utils/connectors';

//!!! Temporary place for this function, move it to API folder after refactors
const getProfile = async () => {
  try {
    return await Api.get('/user/profile/2');
  } catch (err) {
    Sentry.captureException(err);
    throw err;
  }
};

export const getAndSetUserData = async () => {
  try {
    const { data } = await getProfile();
    const { preferences, ...rest } = data.data;
    PermissionService.setUserPermissions(data.data, preferences.settings);
    AnalyticsService.pushUser(data.data);
    HotjarService.setUser(data.data.id);
    setZenDeskChatData(data.data);
    store.dispatch(AuthActions.login(rest));
    store.dispatch(UserActions.setPreferences({ branch: 'profile', preferences }));
    return rest;
  } catch (err) {
    throw err;
  }
};
