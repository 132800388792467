import { ACCESS_TOKENS } from 'configs';
import { cookiesAreEnabled, cookieTypes } from './../initOneTrust';

export const initTagManager = () => {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0];
    var j = d.createElement(s);

    var dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', ACCESS_TOKENS.TAG_MANAGER_ID);
};

export const GTagService = {
  init: function () {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied',
    });
    gtag('js', new Date());
    gtag('config', ACCESS_TOKENS.GTAG_ID);
    window.gtag = gtag;
  },
  config: function () {
    cookiesAreEnabled(cookieTypes.THIRD_PARTY).then((enabled) => {
      if (!enabled) return;
      window.gtag('consent', 'update', {
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        ad_storage: 'granted',
        analytics_storage: 'granted',
      });
    });
  },
  setGA4UserId: function (userId) {
    if (!window.gtag) return;
    window.gtag('config', ACCESS_TOKENS.GTAG_ID, { user_id: userId });
  },
  sendGA4Event: function (event, body) {
    if (!window.gtag || event === 'pageview') return;
    window.gtag('event', `app_${event}`, body);
  },
};
