// Load vendors
import axios from 'axios';

// Load configs
import { HOST } from 'configs/host';
import moment from 'moment';

const KRISP_ORIGIN_TIMEZONE = moment().format('Z');
const KRISP_HEADER_WEB_PROJECT = 'note';
const BASIC_API_AUTH = 'Basic admin:admin';

export const Api = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  headers: {
    krisp_origin_timezone: KRISP_ORIGIN_TIMEZONE,
    krisp_header_web_project: KRISP_HEADER_WEB_PROJECT,
  },
  data: {},
});

export const BasicApi = axios.create({
  baseURL: `${HOST.API.URL}/${HOST.API.API_PREFIX}`,
  headers: {
    Authorization: BASIC_API_AUTH,
    krisp_header_web_project: KRISP_HEADER_WEB_PROJECT,
  },
});

export const PublicApi = axios.create({
  baseURL: `${HOST.API.URL}/api/platform/v1/`,
  headers: {
    krisp_origin_timezone: KRISP_ORIGIN_TIMEZONE,
    krisp_header_web_project: KRISP_HEADER_WEB_PROJECT,
  },
  data: {},
});

export const isAxiosError = axios.isAxiosError;

export const setAuthToken = (authToken) => {
  Api.defaults.headers['Authorization'] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
  PublicApi.defaults.headers['Authorization'] = `${HOST.API.AUTH_PREFIX} ${authToken}`;
};

Api.interceptors.request.use((config) => {
  if (config.url) {
    const url = config.url.replace(/\s+/g, '');
    const blackListedPatterns = ['..', './', '.\\', '%2e%2e', '%2e/', '%2e%2F', '%2e\\'];
    if (blackListedPatterns.some((pattern) => url.includes(pattern))) {
      return Promise.reject(new Error('Invalid URL path'));
    }
  }
  return config;
});
