import { useState, createContext, useContext, useRef, useCallback } from 'react';
import io from 'socket.io-client';
import { HOST } from 'configs';
import { useSnackbar } from 'notistack';
import { generateRid } from 'utils/appHelpers';

const socketUrl = HOST.SOCKET.URI;
const authPrefix = HOST.API.AUTH_PREFIX;

const SocketContext = createContext(null);

export const SocketEventGroups = {
  SUBSCRIBE: 'subscribe',
  SUBSCRIPTION_MESSAGE: 'subscription_message',
};

export const SocketEvents = {
  SYNC_WEB_PROFILE: 'sync_web_profile',
  SYNC_CUSTOM_VOCABULARY: 'sync_custom_vocabulary',
  SYNC_ACTIVITY_EVENT: 'sync_activity_event',
  SYNC_ACTIVITY_COUNTER: 'sync_activity_counter',
  SYNC_CALENDAR: 'sync_calendar',
  SYNC_MEETING: 'sync_meeting',
  APP_SIGN_IN: 'app_sign_in',
  SYNC_AGENDA_ITEMS: 'sync_agenda_items',
};

const SocketProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const socketRef = useRef(null);
  const [connected, setConnected] = useState(false);
  const emitters = useRef({});
  const listenersMapping = useRef(new Map());

  const connect = useCallback((token) => {
    socketRef.current = io(socketUrl, {
      path: '/websockets',
      auth: { token: `${authPrefix} ${token}` },
      autoConnect: true,
      transports: ['websocket'],
      upgrade: true,
      reconnectionDelayMax: 60 * 60 * 1000,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1 * 1000,
      randomizationFactor: 1.5,
    });

    socketRef.current.on('disconnect', () => {
      setConnected(false);
    });

    socketRef.current.on('connect', () => {
      setConnected(true);
    });

    socketRef.current.on('reconnect', () => {
      setConnected(true);

      Object.keys(emitters.current).forEach((event) => {
        socketRef.current.emitWithAck(event, ...emitters.current[event]);
      });
    });

    socketRef.current.on('connect_error', () => {
      enqueueSnackbar('Socket connection error!', { variant: 'error' });
    });

    return socketRef.current;
  }, []);

  const disconnect = useCallback(() => {
    setConnected(false);
    if (!socketRef.current) return;
    socketRef.current.off('disconnect');
    socketRef.current.off('connect');
    socketRef.current.off('reconnect');
    socketRef.current.disconnect();
  }, []);

  const subscribe = useCallback((subscribeEvent, listeningEvent, callback) => {
    const listeners = listenersMapping.current;

    if (!listeners.has(subscribeEvent)) {
      listeners.set(subscribeEvent, new Map());
      listeners.get(subscribeEvent).set(listeningEvent, [callback]);

      socketRef.current.on(subscribeEvent, (data) => {
        const subscribedEvent = listeners.get(subscribeEvent);
        if (subscribedEvent.has(data.event)) {
          subscribedEvent.get(data.event).forEach((cb) => cb(data));
        }
      });
    }

    if (!listeners.get(subscribeEvent).has(listeningEvent)) {
      listeners.get(subscribeEvent).set(listeningEvent, [callback]);
    }

    const callbacks = listeners.get(subscribeEvent).get(listeningEvent);

    const isCallbackExist = callbacks.some((cb) => cb === callback);

    if (!isCallbackExist) {
      listeners.get(subscribeEvent).set(listeningEvent, [...callbacks, callback]);
    }

    return () => {
      const eventListeners = listeners.get(subscribeEvent).get(listeningEvent);

      if (eventListeners) {
        listeners.get(subscribeEvent).set(
          listeningEvent,
          eventListeners.filter((cb) => cb !== callback),
        );
      }

      if (eventListeners.size === 0) {
        listeners.get(subscribeEvent).delete(listeningEvent);
      }

      if (listeners.get(subscribeEvent).size === 0) {
        listeners.delete(subscribeEvent);
        socketRef.current.off(subscribeEvent);
      }
    };
  }, []);

  const unsubscribe = useCallback((event, cb) => {
    socketRef.current?.off(event, cb);
  }, []);

  const emitWithAckEvent = useCallback((event, ...args) => {
    emitters.current[event] = { ...args };
    socketRef.current?.emitWithAck(event, ...args);
  }, []);

  const subscribeToEntity = useCallback((messageName, { entity, key }) => {
    socketRef.current?.emitWithAck(SocketEventGroups.SUBSCRIBE, {
      req_id: generateRid(),
      data: {
        event_infos: {
          [messageName]: {
            [`${messageName}_info`]: {
              entity: entity,
              key: key,
            },
          },
        },
      },
    });
  }, []);

  return (
    <SocketContext.Provider
      value={{
        connected,
        connect,
        subscribe,
        emitWithAckEvent,
        subscribeToEntity,
        disconnect,
        unsubscribe,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

const useSocket = () => {
  const context = useContext(SocketContext);

  if (context === null) {
    throw new Error('useSocket must be used within SocketProvider');
  }

  return context;
};

export { useSocket, SocketProvider };
