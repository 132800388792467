import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Load Vendors
import { getPageTitle } from 'utils/appHelpers';

const RouteTitle = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const title = getPageTitle(location.pathname);
    document.title = title;
  }, [location.pathname]);

  return children;
};

export default RouteTitle;
