import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux_store/actionTypes';
import cloneDeep from 'lodash/cloneDeep';
import filter from 'lodash/filter';

const initialState = null;

const handlers = {
  [USER.SET_DOMAINS]: (state, action) => action.payload,
  [USER.UPDATE_DOMAIN]: (state, action) => {
    const newState = cloneDeep(state);
    const index = newState.findIndex((item) => item.id === action.payload.id);
    newState[index] = action.payload;
    return newState;
  },
  [USER.REMOVE_DOMAIN]: (state, action) => filter(state, (item) => item.id !== action.payload),
  [USER.REMOVE_DOMAINS]: () => initialState,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
