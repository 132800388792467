import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { getError } from 'utils/appHelpers';
import BillingService from 'services/BillingService';
import BillingActions from 'redux_store/actions/billingActions';
import { useDispatch, useSelector } from 'react-redux';

let isFetching = false;

const useTeamStatus = ({ prefetch = false, suspend = false } = {}) => {
  const teamStatus = useSelector((state) => state.teamStatus);
  const dispatch = useDispatch();

  const getTeamStatus = async () => {
    if (isFetching) return;
    try {
      isFetching = true;
      const status = await BillingService.fetchTeamBillingStatus();
      dispatch(BillingActions.setTeamStatus(status));
    } catch (err) {
      enqueueSnackbar(getError(err), { variant: 'error' });
    } finally {
      isFetching = false;
    }
  };

  useEffect(() => {
    if (prefetch && !teamStatus) getTeamStatus();
  }, [prefetch, teamStatus]);

  if (suspend && !teamStatus) throw getTeamStatus();

  return {
    data: teamStatus,
    fetch: getTeamStatus,
    isFetching,
  };
};

export default useTeamStatus;
