import isObject from 'lodash/isObject';
import { JSONParse } from 'utils/appHelpers';

const isStorageSupported = typeof Storage !== 'undefined';

const storage_keys = {
  token: {
    name: 'auth',
    storage: 'localStorage',
    type: 'authFlow',
  },
  app_jwt: {
    name: 'app_jwt',
    storage: 'sessionStorage',
    type: 'appAuthFlow',
  },
  app_account: {
    name: 'app_account',
    storage: 'sessionStorage',
    type: 'appAuthFlow',
  },
  is_app_authorization_flow: {
    name: 'is_app_authorization_flow',
    storage: 'sessionStorage',
    type: 'appAuthFlow',
  },
  auth_ext: {
    name: 'auth_ext',
    storage: 'sessionStorage',
    type: 'authFlow',
  },
  is_first_time_login: {
    name: 'is_first_time_login',
    storage: 'localStorage',
    type: 'authFlow',
  },
  is_upgrade_from_free_plan: {
    name: 'is_upgrade_from_free_plan',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  is_team_create_flow: {
    name: 'is_team_create_flow',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  locale: {
    name: 'locale',
    storage: 'localStorage',
    type: 'userFlow',
  },
  lang: {
    name: 'lang',
    storage: 'localStorage',
    type: 'userFlow',
  },
  from_referral_link: {
    name: 'from_referral_link',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  rec_notification_viewed: {
    name: 'rec-notification-viewed',
    storage: 'localStorage',
    type: 'userFlow',
  },
  installationID: {
    name: 'installationID',
    storage: 'localStorage',
    type: 'authFlow',
  },
  type_form: {
    name: 'typeForm',
    storage: 'localStorage',
    type: 'userFlow',
  },
  exclude_typeform: {
    name: 'exclude_typeform',
    storage: 'localStorage',
    type: 'userFlow',
  },
  isFirstTimeSignUp: {
    name: 'isFirstTimeSignUp',
    storage: 'localStorage',
    type: 'userFlow',
  },
  hasAttemptedAppSignin: {
    name: 'hasAttemptedAppSignin',
    storage: 'localStorage',
    type: 'authFlow',
  },
  account: {
    name: 'account',
    storage: 'localStorage',
    type: 'authFlow',
  },
  mobileDownload: {
    name: 'mobileDownload',
    storage: 'localStorage',
    type: 'mobileDownload',
  },
  last_signed_account_id: {
    name: 'last_signed_account_id',
    storage: 'localStorage',
    type: 'userFlow',
  },
  redirect_path: {
    name: 'redirect_path',
    storage: 'localStorage',
    type: 'userFlow',
  },
  oauth_redirect_path: {
    name: 'oauth_redirect_path',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  email_grant: {
    name: 'email_grant',
    storage: 'localStorage',
  },
  email_code: {
    name: 'email_code',
    storage: 'localStorage',
  },
  email_verification_step: {
    name: 'email_verification_step',
    storage: 'localStorage',
  },
  direct_pro_checkout: {
    name: 'direct_pro_checkout',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  skipTeamJoinPage: {
    name: 'skipTeamJoinPage',
    storage: 'localStorage',
    type: 'userFlow',
  },
  from_plan: {
    name: 'from_plan',
    storage: 'localStorage',
    type: 'userFlow',
  },
  from_interval: {
    name: 'from_interval',
    storage: 'localStorage',
    type: 'userFlow',
  },
  show_admin_warning: {
    name: 'show_admin_warning',
    storage: 'localStorage',
  },
  usersFiltersWereShown: {
    name: 'usersFiltersWereShown',
    storage: 'localStorage',
  },
  devicesFiltersWereShown: {
    name: 'devicesFiltersWereShown',
    storage: 'localStorage',
  },
  invite_teammate_flow: {
    name: 'invite_teammate_flow',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  old_versions: {
    name: 'old_versions',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  until_support_date: {
    name: 'until_support_date',
    storage: 'localStorage',
    type: 'userFlow',
  },
  until_support_dismiss: {
    name: 'until_support_dismiss',
    storage: 'localStorage',
    type: 'userFlow',
  },
  until_end_dismiss: {
    name: 'until_end_dismiss',
    storage: 'localStorage',
    type: 'userFlow',
  },
  until_end_dismiss_date: {
    name: 'until_end_dismiss_date',
    storage: 'localStorage',
    type: 'userFlow',
  },
  end_dismiss: {
    name: 'end_dismiss',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  until_support_date_user: {
    name: 'until_support_date_user',
    storage: 'localStorage',
    type: 'userFlow',
  },
  hide_billing_banner: {
    name: 'hide_billing_banner',
    storage: 'localStorage',
    type: 'userFlow',
  },
  hide_storage_usage_banner: {
    name: 'hide_storage_usage_banner',
    storage: 'localStorage',
    type: 'userFlow',
  },
  until_support_dismiss_user: {
    name: 'until_support_dismiss_user',
    storage: 'localStorage',
    type: 'userFlow',
  },
  until_end_dismiss_user: {
    name: 'until_end_dismiss_user',
    storage: 'localStorage',
    type: 'userFlow',
  },
  until_end_dismiss_date_user: {
    name: 'until_end_dismiss_date_user',
    storage: 'localStorage',
    type: 'userFlow',
  },
  end_dismiss_user: {
    name: 'end_dismiss_user',
    storage: 'sessionStorage',
    type: 'userFlow',
  },
  closed_analytics_alert_box: {
    name: 'closed_analytics_alert_box',
    storage: 'localStorage',
    type: 'userFlow',
  },
  closed_analytics_usernames_alert_box: {
    name: 'closed_analytics_usernames_alert_box',
    storage: 'localStorage',
    type: 'userFlow',
  },
  analytics_feedback_banner: {
    name: 'analytics_feedback_banner',
    storage: 'localStorage',
    type: 'userFlow',
  },
  do_not_show_calls_less_5_minute: {
    name: 'do_not_show_calls_less_5_minute',
    storage: 'localStorage',
    type: 'userFlow',
  },
  connect_calendar_alert_hidden: {
    name: 'connect_calendar_alert_hidden',
    storage: 'localStorage',
  },
  skip_download: {
    name: 'skip_download',
    storage: 'localStorage',
  },
  roi_banner_next_show_time: {
    name: 'roi_banner_next_show_time',
    storage: 'localStorage',
  },
  speaker_edit_instructions_shown: {
    name: 'speaker_edit_instructions_shown',
    storage: 'localStorage',
  },
  temp_hide_embedding_tooltip: {
    name: 'temp_hide_embedding_tooltip',
    storage: 'localStorage',
  },
  temp_last_speaker_setting_date: {
    name: 'temp_last_speaker_setting_date',
    storage: 'localStorage',
  },
  temp_last_speaker_set_tooltip_shown_count: {
    name: 'temp_last_speaker_set_tooltip_shown_count',
    storage: 'localStorage',
  },
  temp_show_embedding_tip_banner_count: {
    name: 'temp_show_embedding_tip_banner_count',
    storage: 'localStorage',
  },
  was_previously_registered: {
    name: 'was_previously_registered',
    storage: 'localStorage',
  },
  notes_redirect_path: {
    name: 'notes_redirect_path',
    storage: 'localStorage',
  },
  vocab_modal_shown: {
    name: 'vocab_modal_shown',
    storage: 'localStorage',
  },
  vocabulary_viewed: {
    name: 'vocabulary_viewed',
    storage: 'localStorage',
  },
  skip_calendar_access_v2: {
    name: 'skip_calendar_access_v2',
    storage: 'localStorage',
  },
  setup_calling_apps: {
    name: 'setup_calling_apps',
    storage: 'sessionStorage',
  },
  is_download_first: {
    name: 'is_download_first',
    storage: 'sessionStorage',
    type: 'authFlow',
  },
  low_confidence_tooltip_shown: {
    name: 'low_confidence_tooltip_shown',
    storage: 'localStorage',
  },
  trial_modal_showed: {
    name: 'trial_modal_showed',
    storage: 'localStorage',
  },
  meetings_list_scrolled_position: {
    name: 'meetings_list_scrolled_position',
    storage: 'sessionStorage',
  },
  coupon_code: {
    name: 'coupon_code',
    storage: 'sessionStorage',
  },
  auto_open_count: {
    name: 'auto_open_count',
    storage: 'localStorage',
  },
  allow_auto_open: {
    name: 'allow_auto_open',
    storage: 'localStorage',
  },
  hide_maintenance_banner_org_release: {
    name: 'hide_maintenance_banner_org_release',
    storage: 'localStorage',
    type: 'userFlow',
  },
  workspace_preview_grant: {
    name: 'workspace_preview_grant',
    storage: 'sessionStorage',
    type: 'authFlow',
  },
  custom_discount_viewed_date: {
    name: 'custom_discount_viewed_date',
    storage: 'localStorage',
    type: 'userFlow',
  },
  custom_discount: {
    name: 'custom_discount',
    storage: 'localStorage',
    type: 'authFlow',
  },
  sidebar_collapsed: {
    name: 'sidebar_collapsed',
    storage: 'localStorage',
    type: 'userFlow',
  },
  request_meeting_access_data: {
    name: 'request_meeting_access_data',
    storage: 'localStorage',
    type: 'userFlow',
  },
  share_loop_flow: {
    name: 'share_loop_flow',
    storage: 'localStorage',
  },
  hide_new_settings_tooltip: {
    name: 'hide_new_settings_tooltip',
    storage: 'localStorage',
    type: 'userFlow',
  },
  whats_new_modal: {
    name: 'whats_new_modal_version',
    storage: 'localStorage',
  },
  team_invite_grant: {
    name: 'team_invite_grant',
    storage: 'localStorage',
  },
  onboarding_checklist: {
    name: 'onboarding_checklist',
    storage: 'localStorage',
  },
  calendar_connect_session_id: {
    name: 'calendar_connect_session_id',
    storage: 'sessionStorage',
  },
  campaign_resurrection: {
    name: 'campaign_resurrection',
    storage: 'sessionStorage',
  },
  find_and_replace_info_banner_dismissed: {
    name: 'find_and_replace_info_banner_dismissed',
    storage: 'localStorage',
  },
  stored_coupons: {
    name: 'stored_coupons',
    storage: 'localStorage',
    type: 'authFlow',
  },
  agendas_filter_contains: {
    name: 'agendasFiltersContains',
    storage: 'localStorage',
    type: 'authFlow',
  },
  agendas_hidden_ids: {
    name: 'agendasHiddenIds',
    storage: 'localStorage',
    type: 'authFlow',
  },
  timeline_open: {
    name: 'timeline_open',
    storage: 'localStorage',
  },
  agenda_info_banner_closed: {
    name: 'agenda_info_banner_closed',
    storage: 'localStorage',
  },
  agenda_rated_date: {
    name: 'agenda_rated_date',
    storage: 'localStorage',
    type: 'userFlow',
  },
};

const getKeyItem = (key) => {
  if (!isStorageSupported) return null;
  if (!storage_keys[key]) {
    throw new Error(`Storage key ${key} is not supported`);
  }
  return storage_keys[key];
};

const getStorage = (item) => {
  if (!isStorageSupported) return null;
  const storages = {
    localStorage: window.localStorage,
    sessionStorage: window.sessionStorage,
  };
  return storages[item.storage];
};

export const StorageService = {
  onStorageUpdate: (callback) => {
    window.addEventListener('storage', ({ key, newValue }) => {
      const action = { type: null, value: newValue };
      if (key === 'auth') action.type = newValue ? 'login' : 'logout';
      callback && callback(action);
    });
  },
  set: (key, value) => {
    const keyItem = getKeyItem(key);
    if (!keyItem) return;
    if (isObject(value)) value = JSON.stringify(value);
    getStorage(keyItem).setItem(keyItem.name, value);
  },
  get: (key) => {
    const keyItem = getKeyItem(key);
    if (!keyItem) return null;
    let keyValue = getStorage(keyItem).getItem(keyItem.name);
    const parsed = JSONParse(keyValue);
    if (parsed !== null && typeof parsed === 'object') {
      keyValue = parsed;
    }
    // Hotfix in case of "new Date()" has been set as a "string" value, which is actually an object
    if (typeof keyValue === 'string' && keyValue?.startsWith('"') && keyValue?.endsWith('"')) {
      keyValue = keyValue.slice(1, -1);
    }
    return keyValue !== 'null' ? keyValue : null;
  },
  remove: (key) => {
    const keyItem = getKeyItem(key);
    if (!keyItem) return;
    getStorage(keyItem).removeItem(keyItem.name);
  },
  removeTypes: (types = []) => {
    if (!types || !types.length) return;
    Object.values(storage_keys).forEach((item) => {
      if (types.includes(item.type)) {
        getStorage(item).removeItem(item.name);
      }
    });
  },
};
