import i18n from 'i18next';
import moment from 'moment';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

moment.locale('en-us');

export const languages = [
  {
    name: 'English',
    value: 'en-US',
    code: 'en',
  },
];

const fallbackLng = ['en'];
const whitelist = languages.map((item) => item.code);

const configs = {
  fallbackLng,
  whitelist,
  nonExplicitWhitelist: true,
  cleanCode: true,
  initImmediate: false,
  interpolation: {
    escapeValue: false,
  },
  detection: {
    lookupQuerystring: 'locale',
    lookupCookie: 'lang',
    lookupLocalStorage: 'lang',
  },
  backend: {
    queryStringParams: { v: import.meta.env.VITE_VERSION },
  },
  load: 'languageOnly', // This will ensure it uses just 'en' instead of 'en-US'
};

i18n.use(HttpBackend).use(intervalPlural).use(LanguageDetector).use(initReactI18next).init(configs);

// ph_      -> placeholders
// msg_     -> alert messages
// title_   -> title
// note_    -> note
// link_    -> link
// btn_     -> button
// btn_text -> only text button
// nav_     -> navigation
// listh_   -> list header
// label_   -> label (ex: name: Davit)
// _plural  -> for plural texts
