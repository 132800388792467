// Load Vendors
import moment from 'moment';
import { dateFormat } from 'utils/dateHelpers';
import { calcPercent, formatBytes } from 'utils/appHelpers';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';

// Load Const
import {
  DEFAULT_STORAGE_PLAN,
  DEFAULT_STORAGE_SIZE,
  planCurrencies,
  planRules,
  validPlanTransitions,
  validPlanTransitionsPaypal,
} from 'configs/consts';

export const calcPlanAmount = (currency, amount) => {
  if (!amount) return amount;
  return currency === 'usd' ? amount / 100 : amount;
};

export const planIs = (type, billing_interval, currency, sub_type) => (plan) => {
  return (
    plan.type === type &&
    plan.billing_interval === billing_interval &&
    plan.currency === currency &&
    plan.sub_type === sub_type
  );
};

export const getSeatAmount = (seatsCount, plan) => {
  if (!plan?.tiers) return plan?.amount || plan?.paypal?.amount || 0;
  let tier;
  plan.tiers.forEach((item) => {
    if (!tier && (seatsCount <= item.up_to || item.up_to === null)) {
      tier = item;
    }
  });
  return tier.unit_amount;
};

export const isSourceNextCoupon = (name = '') => name.startsWith('SN');

export const isAppsumoCoupon = (name = '') => name.startsWith('AS');

export const isNonStripeCoupon = (name) => isSourceNextCoupon(name) || isAppsumoCoupon(name);

export const getCouponData = (coupon, totalAmount = 0) => {
  if (!coupon) return null;
  const { currency, amount_off, duration_in_months, percent_off, duration, id, name } = coupon;
  const isPercentage = !!percent_off;
  const amountWithPercent = percent_off && (totalAmount * percent_off) / 100;
  const amountFixed = currency === 'usd' ? amount_off / 100 : amount_off;

  return {
    currency: planCurrencies[currency],
    amount: isPercentage ? amountWithPercent : amountFixed,
    duration: duration_in_months,
    percent_off: percent_off ? `${percent_off}%` : null,
    name,
    is_non_stripe: isNonStripeCoupon(name),
    id,
    isOneTime: duration === 'once',
  };
};

export const setPlanAmount = (plan) => {
  return calcPlanAmount(plan.currency, plan.amount);
};

export const setPlanTiersAmount = (plan) => {
  if (!plan.tiers) return plan.tiers;
  return plan.tiers.map((item) => {
    item.unit_amount = calcPlanAmount(plan.currency, item.unit_amount);
    return item;
  });
};

export const setPaypalAmount = (paypalPlan) => {
  const billingCycle = paypalPlan?.billing_cycles?.[0];
  if (!billingCycle) return 0;
  const amountStr = billingCycle.pricing_scheme.fixed_price.value;
  return parseInt(amountStr, 10);
};

export const normalizeBillingSeats = (body) => {
  for (let i in body) {
    body[i] = +body[i];
  }
  const { standard = 0, device = 0, appsumo = 0, used = 0, trial = 0 } = body;
  body.chargeSeatsCount = standard + device + trial;
  body.currentSeatsCount = body.chargeSeatsCount + appsumo;
  body.unassignedSeatsCount = body.currentSeatsCount + trial - used;
  body.trialSeatsCount = trial;

  return body;
};

export const constructBillingObj = (body) => {
  if (body) body.seats = normalizeBillingSeats(body.seats || {});
  if (body?.pricing_plan) {
    body.pricing_plan = normalizePlanObject(body.pricing_plan);
    body.pricing_plan.key = Object.keys(planRules).find((k) =>
      planIs.apply(null, planRules[k])(body.pricing_plan),
    );

    if (body.pricing_plan?.upgrades) {
      body.pricing_plan.upgrades = body?.pricing_plan?.upgrades?.map((upgradePlan) =>
        normalizePlanObject(upgradePlan),
      );
    }
  }
  return body;
};

export const normalizePlanObject = (plan) => {
  if (!plan) return {};
  const { stripe_plan, paypal_plan, paypal_plan_id, ...rest } = plan;
  const planObject = { ...rest, ...stripe_plan, paypal: paypal_plan };

  if (planObject) {
    planObject.currencySymbol = planCurrencies[planObject.currency];
    planObject.amount = setPlanAmount(planObject);
    planObject.tiers = setPlanTiersAmount(planObject);
  }
  if (planObject && planObject.paypal) {
    planObject.paypal.amount = setPaypalAmount(planObject.paypal);
    planObject.paypal.id = paypal_plan_id;
  }
  return planObject;
};

const storageGroupName = ({ type, billing_interval: interval, currency }) => {
  const intervals = { month: 'Monthly', year: 'Annual' };
  const currencies = { usd: '', ypy: 'Yen' };
  return `${camelCase(type)}${intervals[interval]}${currencies[currency]}`;
};

export const constructPlansObj = (body) => {
  const plans = {};
  const { default: defaultPlans, storage } = groupBy(body, 'sub_type');

  for (let k in planRules) {
    const plan = defaultPlans.find(planIs.apply(null, planRules[k]));
    const planObject = normalizePlanObject(plan);
    planObject.upgrades = planObject?.upgrades?.map(normalizePlanObject);
    planObject.name = k;
    plans[k] = planObject;
  }

  plans.storage = groupBy(storage, storageGroupName);

  return plans;
};

export const getOneDayPrice = (seatsCount, billing) => {
  const seatAmount = getSeatAmount(seatsCount, billing.pricing_plan);
  const start = billing.current_period_start * 1000;
  const end = billing.current_period_end * 1000;
  const minutes = moment(end).diff(start, 'minutes');
  return Number(seatAmount / (minutes / 24 / 60));
};

export const getOneDayTax = (billing) => {
  const taxAmount = billing.tax;
  const start = billing.current_period_start * 1000;
  const end = billing.current_period_end * 1000;
  const minutes = moment(end).diff(start, 'minutes');
  return Number(taxAmount / (minutes / 24 / 60));
};

export const getDueTodayPrice = (seatsCount, billing) => {
  const remainingTime = moment(billing.current_period_end * 1000).diff(
    moment(),
    'milliseconds',
    true,
  );
  const timePaidFor = moment(billing.current_period_end * 1000).diff(
    moment(billing.current_period_start * 1000),
    'milliseconds',
    true,
  );
  return (
    (remainingTime * seatsCount * getSeatAmount(seatsCount, billing.pricing_plan)) / timePaidFor
  );
};

export const planPeriodEndDay = (currentPeriodEnd) => {
  if (!currentPeriodEnd) return 0;
  const minute = moment(currentPeriodEnd * 1000)
    .utc()
    .diff(moment().utc(), 'minute');
  return minute / 60 / 24;
};

export const getPlanExpirationDate = (current_period_end, latest_invoice) => {
  if (!current_period_end) return null;
  let currenPeriodEnd = dateFormat(moment(current_period_end * 1000).utc(), 'MMM DD, YYYY');
  if (latest_invoice && latest_invoice.status === 'open') {
    currenPeriodEnd = dateFormat(moment(latest_invoice.date).add(9, 'days'), 'MMM DD, YYYY');
  }
  return currenPeriodEnd;
};

export const getPlanExpirationDaysLeft = (date) => {
  if (!date) return 0;
  const minute = moment(date * 1000)
    .utc()
    .diff(moment().utc(), 'minute');
  return Math.ceil(minute / 60 / 24);
};

export const calcPriceWithCoupon = (coupon, planPrice) => {
  if (!coupon) return 0;
  const amount = calcPlanAmount(coupon.currency, coupon.amount_off);
  return amount !== null ? amount : calcPercent(planPrice, coupon.percent_off);
};

export const getPlanComparisonPercentage = (annualPlanAmount, monthlyPlanAmount) => {
  return (100 - (annualPlanAmount * 100) / (monthlyPlanAmount * 12)).toFixed();
};

export const getPlanAmount = (plan, type) => {
  const currentPlan = plan && type === 'paypal' ? plan.paypal : plan;
  return currentPlan && currentPlan.amount;
};

export const getDueTodayPriceTotal = (todayPrice, tax, currentPrice) => {
  return todayPrice * (1 + tax / currentPrice);
};

export const getDueNextPriceTotal = (totalSeatsCount, seatAmount, tax, overallSeats) => {
  return totalSeatsCount * (seatAmount + tax / overallSeats);
};

export const getCouponAmount = (coupon) => {
  if (!coupon) return null;
  return coupon.currency && coupon.amount
    ? `${coupon.currency}${coupon.amount}`
    : coupon.percent_off
    ? `${coupon.percent_off}%`
    : null;
};

export const normalizeStorageAddon = (plan) => {
  const storageSize = +plan.stripe_plan.metadata?.storage_size;
  return {
    ...plan,
    amount: plan.stripe_plan.amount / 100, // convert to dollars
    size: storageSize,
    formattedSize: formatBytes(storageSize),
  };
};

export const isValidPlanTransition = (currentPlanName, newPlanName, isPaypal = false) => {
  const validTransitions = isPaypal ? validPlanTransitionsPaypal : validPlanTransitions;
  return validTransitions[currentPlanName]?.includes(newPlanName) ?? false;
};

export const getDefaultStoragePlan = (defaultStorageSize) => {
  const defaultStoragePlan = cloneDeep(DEFAULT_STORAGE_PLAN);
  defaultStoragePlan.stripe_plan.metadata.storage_size = defaultStorageSize ?? DEFAULT_STORAGE_SIZE;
  return defaultStoragePlan;
};
