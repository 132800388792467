const getExpireDate = (day = 365) => {
  const expire = new Date();
  expire.setTime(expire.getTime() + 1000 * 60 * 60 * 24 * day);
  return expire.toGMTString();
};

const cookie_keys = {
  krisp_account_type: {
    name: 'krispAccountType',
  },
  userId: {
    name: 'userId',
  },
  krispSessionId: {
    name: 'krispSessionId',
  },
  anonymousId: {
    name: 'anonymousId',
  },
  ref_source_affiliate: {
    name: 'refSource',
    domain: 'krisp.ai',
  },
  path: {
    name: 'path',
  },
};

const getKeyItem = (key) => {
  if (!cookie_keys[key]) {
    throw new Error(`Unknown key "${key}"`);
  }
  return cookie_keys[key];
};

export const CookieService = {
  set: (key, value, days) => {
    const keyItem = getKeyItem(key);
    if (!keyItem) return;
    const {
      name,
      expire = days === '' ? '' : getExpireDate(days),
      path = '/',
      domain = '.krisp.ai',
    } = keyItem;
    document.cookie = `${name}=${value}; expires=${expire}; path=${path}; domain=${domain};`;
  },
  get: (key) => {
    const keyItem = getKeyItem(key);
    if (!keyItem) return null;
    const name = keyItem.name + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  },
  remove: (key) => {
    const keyItem = getKeyItem(key);
    if (!keyItem) return;
    const { name, path = '/', domain = '.krisp.ai' } = keyItem;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; domain=${domain};`;
  },
  bulkSet: function (obj) {
    if (typeof obj !== 'object' || obj === null) return;
    Object.keys(obj).forEach((key) => {
      this.set(key, obj[key]);
    });
  },
  bulkRemove: function (arr) {
    if (!arr || !arr.length) return;
    arr.forEach((key) => {
      this.remove(key);
    });
  },
};
