import { ACCESS_TOKENS } from './configs';
import { cookiesAreEnabled, cookieTypes } from './initOneTrust';
import { HotjarService } from 'services/HotjarService';

const initHotjar = async () => {
  const enabled = await cookiesAreEnabled(cookieTypes.THIRD_PARTY);
  if (!enabled) return;
  const { HOTJAR_ID, HOTJAR_SNIPPET_VERSION } = ACCESS_TOKENS;
  HotjarService.init(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
};

export default initHotjar;
