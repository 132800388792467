import cloneDeep from 'lodash/cloneDeep';
import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux_store/actionTypes';

const initialState = null;

const handlers = {
  [USER.SET_RECORDS]: (state, action) => action.payload,
  [USER.UPDATE_RECORD]: (state, action) => {
    const rows = cloneDeep(state.rows);
    const itemIndex = rows.findIndex((i) => i.recording_id === action.payload.recording_id);
    rows[itemIndex] = { ...rows[itemIndex], ...action.payload };
    return { ...state, rows };
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
