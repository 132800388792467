import { createReducer } from 'utils/reduxHelpers';
import { AUTH, ACTIVITY } from 'redux_store/actionTypes';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';

const initialState = { data: {}, isVisible: false };

const handlers = {
  [ACTIVITY.SET_ACTIVITIES]: (state, action) => {
    const { tab, data } = action.payload;
    const { items, ...totals } = data;

    const existingItems = state.data[tab] || [];
    const updatedItems = uniqBy([...existingItems, ...items], 'id');

    return {
      ...state,
      ...totals,
      priorities: totals.group_total
        ? Object.values(totals.group_total).reduce((acc, val) => acc + val, 0)
        : 0,
      group_total: totals.group_total,
      data: { ...state.data, [tab]: updatedItems },
    };
  },
  [ACTIVITY.SET_TOTALS]: (state, action) => {
    const { totals } = action.payload;

    const newTotals = {
      total: totals.total || 0,
      unread: totals.unread || 0,
      displayed: totals.displayed || 0,
      group_total: totals.group_total || {},
      priorities: totals.group_total
        ? Object.values(totals.group_total).reduce((acc, val) => acc + val, 0)
        : 0,
    };

    return { ...state, ...newTotals };
  },
  [ACTIVITY.SET_ACTIVITY]: (state, action) => {
    const { items } = action.payload;
    const ALL = 'all';
    const UNREAD = 'unread';
    const PRIORITIES = 'priorities';

    const newGroups = cloneDeep(state.groups || []);
    const newData = cloneDeep(state.data);

    if (!newData[ALL]) newData[ALL] = [];
    if (!newData[UNREAD]) newData[UNREAD] = [];
    if (!newData[PRIORITIES]) newData[PRIORITIES] = [];

    for (const item of items) {
      const activity = item.activity;
      switch (item.action) {
        case 'delete':
          newData[ALL] = newData[ALL].filter((item) => item.id !== activity.id);
          newData[UNREAD] = newData[UNREAD].filter((item) => item.id !== activity.id);
          newData[PRIORITIES] = newData[PRIORITIES].filter((item) => item.id !== activity.id);
          break;
        case 'create':
          newData[ALL].unshift(activity);
          if (!activity.read_at) newData[UNREAD].unshift(activity);
          if (activity.group) newData[PRIORITIES].unshift(activity);
          break;
        case 'update':
          newData[ALL] = newData[ALL].map((item) => (item.id === activity.id ? activity : item));
          newData[PRIORITIES] = newData[PRIORITIES].map((item) =>
            item.id === activity.id ? activity : item,
          );
          if (activity.read_at) {
            newData[UNREAD] = newData[UNREAD].filter((item) => item.id !== activity.id);
          } else {
            newData[UNREAD] = newData[UNREAD].map((item) =>
              item.id === activity.id ? activity : item,
            );
          }
          break;
        default:
          throw new Error('Unknown event type');
      }

      if (item.action === 'create' && activity.group) {
        const group = activity.group;
        const existingGroup = newGroups.find((g) => g.name === group);
        if (existingGroup) {
          existingGroup.items.push(activity);
        } else {
          newGroups.push({ name: group, items: [activity] });
        }
      }
    }

    return { ...state, data: newData, groups: newGroups };
  },
  [ACTIVITY.MART_ALL_AS_READ]: (state) => {
    return {
      ...state,
      data: {
        ...state.data,
        unread: [],
        priorities: state.data.priorities?.map((item) => ({
          ...item,
          read_at: new Date().toISOString(),
        })),
      },
      unread: 0,
    };
  },
  [ACTIVITY.SET_ACTIVITY_VISIBILITY]: (state, action) => {
    return { ...state, isVisible: action.payload };
  },
  [ACTIVITY.SET_GROUPS]: (state, action) => {
    return { ...state, groups: action.payload };
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
