import i18n from 'i18next';
import moment from 'moment';

export const dateFormat = (date, format = 'DD MMM YYYY', altValue, utc) => {
  if (!date) return altValue;
  if (utc) {
    return moment(date).utc().format(format);
  }
  return moment(new Date(date)).locale(i18n.language).format(format);
};

export const dateDistance = (date, measure = 'd') =>
  moment(date).local().startOf(measure).diff(moment().local().startOf(measure), measure);

export const getDateFromSeconds = (seconds) => {
  return moment().add(seconds, 'seconds').format('DD MMM YYYY');
};

export const dateSubtract = (date, value, unit, format) => {
  const sDate = moment(date).subtract(value, unit).valueOf();
  return dateFormat(sDate, format);
};

export const getDuration = (date = Date.now()) =>
  moment.duration(moment(date).diff(Date.now()))?.asMinutes();

export const getDateFromString = (str, isISO) => {
  if (!str) return '';
  let date = new Date(str);
  if (isISO) {
    try {
      date = date.toISOString();
    } catch (e) {
      date = '';
    }
  }
  return date;
};

export const setIdentifiedDate = (date, format = 'MMM DD, dddd') => {
  const dayDiff = dateDistance(date, 'd');
  const yearDiff = dateDistance(date, 'y');

  if (dayDiff === 0) {
    return 'Today';
  } else if (dayDiff === -1) {
    return 'Yesterday';
  } else if (yearDiff !== 0) {
    return dateFormat(date, 'MMM DD, YYYY');
  } else {
    return dateFormat(date, format);
  }
};

export const isSameDate = (date1, date2, granularity) => moment(date1).isSame(date2, granularity);

export const meetingDateFormat = (date) => {
  const startOfToday = moment().local().startOf('d');
  const occuringDate = moment(date).local();
  const isToday = occuringDate.isSame(startOfToday, 'd');
  const isThisYear = occuringDate.isSame(startOfToday, 'year');

  if (isToday) return dateFormat(date, 'hh:mm A');
  if (!isThisYear) return dateFormat(date, 'DD/MM/YYYY');
  return dateFormat(date, 'MMM D');
};

export const getLastActivityDates = (value) => {
  switch (value) {
    case 4:
      return {
        from: moment().subtract(3, 'days').startOf('day').format(),
        to: moment().endOf('day').format(),
      };
    case 3:
      return {
        from: moment().subtract(6, 'days').startOf('day').format(),
        to: moment().endOf('day').format(),
      };
    case 2:
      return {
        from: moment().subtract(29, 'days').startOf('day').format(),
        to: moment().endOf('day').format(),
      };
    case 1:
      return {
        from: moment(0).startOf('day').format(),
        to: moment().subtract(30, 'days').endOf('day').format(),
      };
    case 0:
      return 'pending';
    default:
      return null;
  }
};
