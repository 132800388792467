import store from 'redux_store/store';

const generateCacheKey = (id, resource, access) => {
  return `${id}#${resource}#${access}`; // e.g. 5d2b921061c447a3ae9fc9a35d1ef808#transcript#view
};

export const MeetingAccessService = {
  levels: {
    view: 1,
    edit: 2,
  },
  cache: new Map(),
  clearCache: function () {
    this.cache.clear();
  },
  invalidateCacheForMeeting: function (id) {
    // Iterate over all cache keys and remove those related to the specific meeting ID
    const keysToRemove = [...this.cache.keys()].filter((key) => key.startsWith(id));
    keysToRemove.forEach((key) => this.cache.delete(key));
  },
  hasAccess: function (id, resource, access, givenSelfAccess) {
    if (!id || !resource || !access) {
      return false;
    }

    // Check if the user is authenticated to edit the certain resource
    const isAuthenticated = store.getState().isAuthenticated;
    if (access === 'edit' && !isAuthenticated) return false;

    const cacheKey = generateCacheKey(id, resource, access);

    // Check the access in cache
    if (this.cache.has(cacheKey)) return this.cache.get(cacheKey);

    // Use givenAccesses or retrieve from store if not provided
    const selfAccess =
      givenSelfAccess ?? store.getState().transcripts.meetingsById?.[id]?.self_access;
    if (!selfAccess) return false;

    let hasAccess = false;

    if (selfAccess.is_owner) {
      hasAccess = true;
    } else {
      const selfAccessLevel = this.levels[selfAccess.resources[resource]];
      const givenAccessLevel = this.levels[access];
      hasAccess = selfAccessLevel >= givenAccessLevel;
    }

    // Cache the result before returning
    this.cache.set(cacheKey, hasAccess);

    return hasAccess;
  },
  isOwner: function (id, givenSelfAccess) {
    if (!id) {
      return false;
    }

    // Check the access in cache
    const cacheKey = generateCacheKey(id, 'self_access', 'is_owner');
    if (this.cache.has(cacheKey)) return this.cache.get(cacheKey);

    // Use givenAccesses or retrieve from store if not provided
    const selfAccess =
      givenSelfAccess ?? store.getState().transcripts.meetingsById?.[id]?.self_access;
    if (!selfAccess) return false;

    const isOwner = selfAccess.is_owner;
    this.cache.set(cacheKey, isOwner);

    return isOwner;
  },
  getAccessLevel: function (access) {
    return this.levels[access] ?? 0;
  },
};
