import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux_store/actionTypes';

const initialState = null;

const handlers = {
  [USER.SET_ACCOUNTS]: (state, action) => action.payload,
  [USER.UPDATE_TEAM]: (state, action) => {
    const { team_id, name } = action.payload;
    const newData = state.data.map((account) => {
      if (account.team_id === team_id) account.name = name;
      return account;
    });
    return { ...state, data: newData };
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
