import { ACCESS_TOKENS, getENV, HOST } from './configs';
import { initClient } from 'services/AnalyticsService';
import amplitudeABTestingService from 'services/AmplitudeABTestingService';
import { cookiesAreEnabled, cookieTypes } from './initOneTrust';
const env = getENV();

const initAmplitude = async () => {
  const enabled = await cookiesAreEnabled(cookieTypes.THIRD_PARTY);
  const options = {
    minIdLength: 1,
  };
  if (env !== 'plive' || env !== 'stage') {
    options.serverUrl = `${HOST.API.URL}/api/web-analytics-2`;
  }
  if (!enabled) return;
  initClient(ACCESS_TOKENS.AMPLITUDE_API_KEY, undefined, options);
  amplitudeABTestingService.init();
};

export default initAmplitude;
