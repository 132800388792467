export const QuoraService = {
  trackEvent: function (eventName) {
    if (!window.qp) return;
    try {
      window.qp('track', eventName);
    } catch (err) {
      console.log('QuoraService:trackEvent', err);
    }
  },
};
