import { createReducer } from 'utils/reduxHelpers';
import { AUTH } from 'redux_store/actionTypes';

const initialState = null;

const handlers = {
  [AUTH.SET_STATE]: (state, action) => action.payload,
};

export default createReducer(initialState, handlers);
