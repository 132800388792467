import { Api } from 'utils/connectors';
import {
  calcPlanAmount,
  calcPriceWithCoupon,
  constructPlansObj,
  getCouponData,
  getDueTodayPrice,
  getOneDayPrice,
  getOneDayTax,
  getPlanComparisonPercentage,
  getPlanExpirationDaysLeft,
  getSeatAmount,
  planPeriodEndDay,
} from 'utils/billingHelpers';
import { PermissionService } from './PermissionService';
import { planCurrencies, YenPlansMapping } from 'configs';
import { bindNum } from 'utils/appHelpers';
import { getTaxAmount } from 'utils/taxHelpers';

class BillingService {
  static async fetchPlans() {
    const { data } = await Api.get('payment/plans');
    return constructPlansObj(data.data);
  }

  static async fetchTeamBillingStatus() {
    const { data } = await Api.get('/team/status');
    return data.data;
  }

  static async fetchBilling() {
    const { data } = await Api.get('/payment/billing');
    return data.data;
  }

  static async requestMoreStorage({ message, requestingStoragePlanId }) {
    const response = await Api.post('/team/storage-request', {
      requesting_storage_plan_id: requestingStoragePlanId,
      message,
    });
    return response.data;
  }

  static async createStripeSubscription({ params, body }) {
    const getEndpoint = () => {
      if (this.isTeamTrial() && !this.isTeamFree()) return `/payment/card/${params.billingId}`;
      if (this.isTeamFree()) return '/team/paid';
      return '/payment/plan';
    };

    const endpoint = getEndpoint();
    const response = await Api.put(endpoint, body);

    return response.data;
  }

  static async createPaypalSubscription({ paypalPlanId }) {
    const body = { plan_id: paypalPlanId };
    const response = await Api.put('/payment/paypal/subscribe', body);
    return response.data;
  }

  static async updateCard(billing, { recaptcha_token, payment_method_id }) {
    const isMonthly = this.getPlanInfo(billing.pricing_plan).isMonthly;
    const { proAnnualPlan, proMonthlyPlan } = this.getUpgradablePlans(billing);

    const getPayload = () => {
      const body = {
        recaptcha_token,
        payment_method: { id: payment_method_id },
      };

      if (this.isPaypalSubscription()) {
        return {
          body: { ...body, plan_id: isMonthly ? proMonthlyPlan?.id : proAnnualPlan?.id },
          endpoint: '/payment/plan',
        };
      }

      return { body, endpoint: `/payment/card/${billing.billing_id}` };
    };

    const { body, endpoint } = getPayload();
    const { data } = await Api.put(endpoint, body);
    return data.data;
  }

  static async fetchLocalPromotion() {
    const { data } = await Api.get('payment/coupon/defined/local-promotion');
    return data.data;
  }

  static getAllPlanNames(currency = 'usd') {
    return {
      proMonthly: this.getActualPlanName('proMonthly', currency),
      proAnnual: this.getActualPlanName('proAnnual', currency),
      businessMonthly: this.getActualPlanName('businessMonthly', currency),
      businessAnnual: this.getActualPlanName('businessAnnual', currency),
      maBusinessMonthly: this.getActualPlanName('maBusinessMonthly', currency),
      maBusinessAnnual: this.getActualPlanName('maBusinessAnnual', currency),
    };
  }

  static getUpgradablePlans(billing, currency = 'usd') {
    const planNames = this.getAllPlanNames(currency);
    const proAnnualPlan = billing.upgrades?.[planNames.proAnnual];
    const proMonthlyPlan = billing.upgrades?.[planNames.proMonthly];
    const businessAnnualPlan = billing.upgrades?.[planNames.businessAnnual];
    const businessMonthlyPlan = billing.upgrades?.[planNames.businessMonthly];
    const maBusinessMonthlyPlan = billing.upgrades?.[planNames.maBusinessMonthly];
    const maBusinessAnnualPlan = billing.upgrades?.[planNames.maBusinessAnnual];

    return {
      proAnnualPlan,
      proMonthlyPlan,
      businessAnnualPlan,
      businessMonthlyPlan,
      maBusinessMonthlyPlan,
      maBusinessAnnualPlan,
    };
  }

  static getPlansWithAmount(billing, currency) {
    const upgradablePlans = this.getUpgradablePlans(billing, currency);
    const proAnnualPlanAmount = getSeatAmount(
      billing.seats.chargeSeatsCount,
      upgradablePlans.proAnnualPlan,
    );
    const proMonthlyPlanAmount = getSeatAmount(
      billing.seats.chargeSeatsCount,
      upgradablePlans.proMonthlyPlan,
    );
    const businessAnnualPlanAmount = getSeatAmount(
      billing.seats.chargeSeatsCount,
      upgradablePlans.businessAnnualPlan,
    );
    const businessMonthlyPlanAmount = getSeatAmount(
      billing.seats.chargeSeatsCount,
      upgradablePlans.businessMonthlyPlan,
    );
    const maBusinessMonthlyPlanAmount = getSeatAmount(
      billing.seats.chargeSeatsCount,
      upgradablePlans.maBusinessMonthlyPlan,
    );
    const maBusinessAnnualPlanAmount = getSeatAmount(
      billing.seats.chargeSeatsCount,
      upgradablePlans.maBusinessAnnualPlan,
    );

    const upgradablePlansAmount = {
      proAnnualPlanAmount,
      proMonthlyPlanAmount,
      businessAnnualPlanAmount,
      businessMonthlyPlanAmount,
      maBusinessMonthlyPlanAmount,
      maBusinessAnnualPlanAmount,
    };

    return { upgradablePlans, upgradablePlansAmount };
  }

  static getPlansSavingPersentage(billing, currency) {
    const { upgradablePlans, upgradablePlansAmount } = this.getPlansWithAmount(billing, currency);

    const proSavingPercentageByUpgrade = getPlanComparisonPercentage(
      upgradablePlansAmount.proAnnualPlanAmount,
      upgradablePlansAmount.proMonthlyPlanAmount || this.getCurrentPlanSeatAmount(billing),
    );
    const businessSavingPercentageByUpgrade = getPlanComparisonPercentage(
      upgradablePlansAmount.businessAnnualPlanAmount,
      upgradablePlansAmount.businessMonthlyPlanAmount,
    );
    const maBusinessSavingPercentageByUpgrade = getPlanComparisonPercentage(
      upgradablePlansAmount.maBusinessAnnualPlanAmount,
      upgradablePlansAmount.maBusinessMonthlyPlanAmount,
    );

    const savingPercentageWithCurrentPlan =
      billing.pricing_plan?.type === 'pro'
        ? proSavingPercentageByUpgrade
        : billing.pricing_plan?.type === 'business'
        ? businessSavingPercentageByUpgrade
        : billing.pricing_plan?.type === 'ma_business'
        ? maBusinessSavingPercentageByUpgrade
        : 0;

    const savingPersentages = {
      proSavingPercentageByUpgrade,
      businessSavingPercentageByUpgrade,
      maBusinessSavingPercentageByUpgrade,
      savingPercentageWithCurrentPlan,
    };

    return { upgradablePlans, upgradablePlansAmount, savingPersentages };
  }

  static getCurrentPlan(billing, currency) {
    const seatPriceAmount = billing.pricing_plan?.amount;
    const isMonthly = billing.pricing_plan?.billing_interval === 'month';
    const isTrial = this.isTrial();
    const coupon = getCouponData(billing.coupon);
    const currencyShort = this.getCurrencyShort(currency);
    const billingAmount = calcPlanAmount(currency, billing.amount);
    const discountAmount = calcPlanAmount(currency, billing.discount_amount);
    const paypalTax =
      billing.type === 'paypal'
        ? calcPlanAmount(currency, billing.amount - billing.amount_without_tax)
        : null;
    const chargedSeatsPrice = this.bindPrice({
      price: seatPriceAmount * billing.seats.chargeSeatsCount,
      currency,
    });
    const withoutDiscountAmount = this.bindPrice({
      price: isTrial ? 0 : billingAmount + discountAmount,
      currency,
    });
    const taxAmount = this.bindPrice({
      price: billing.type === 'paypal' ? paypalTax : billing.tax,
      currency,
    });
    const couponAmount = () => {
      return this.bindPrice({
        price: coupon.amount,
        fix: 0,
        altSymbol: `${currencyShort} ${coupon.currency}`,
        currency,
      });
    };
    const totalPrice = this.bindPrice({ price: isTrial ? 0 : billingAmount, currency });
    const seatPrice = this.bindPrice({
      price: isMonthly ? billing.pricing_plan?.amount : billing.pricing_plan?.amount / 12,
      fix: 2,
      currency,
    });

    return {
      billingAmount: billingAmount,
      totalPrice: `${currencyShort} ${totalPrice}`,
      chargedSeatsPrice: `${currencyShort} ${chargedSeatsPrice}`,
      seatPrice: seatPrice,
      seatPriceAmount,
      tax: taxAmount,
      hasTax: !!billing.tax || !!paypalTax,
      id: billing.pricing_plan?.id,
      couponAmount: coupon ? (coupon.percent_off ? coupon.percent_off : couponAmount()) : null,
      withoutDiscountAmount: discountAmount ? `${currencyShort} ${withoutDiscountAmount}` : null,
      discountAmount,
    };
  }

  static getCurrentPlanSeatAmount(billing) {
    return getSeatAmount(billing.seats.chargeSeatsCount, billing.pricing_plan);
  }

  static getPlanSeatAmount(billing, plan) {
    return getSeatAmount(billing.seats.chargeSeatsCount || 1, plan);
  }

  static getPlanType(billing) {
    const isTeamTrial = PermissionService.hasAccess('team_trial');
    const cancellationDate = billing.cancel_at_period_end;
    const latestInvoice = billing.latest_invoice;

    let planType = 'free';
    if (billing?.pricing_plan) {
      const { type, billing_interval } = billing.pricing_plan;
      if (isTeamTrial) {
        planType = `${type}_team_trial`;
      } else {
        planType = `${type}_${billing_interval}`;
        if (!cancellationDate) {
          planType = `${type}_${billing_interval}_renew`;
        }
        if (latestInvoice?.status === 'open') {
          planType = `${type}_${billing_interval}_downgrade`;
        }
      }
    } else if (this.isTrial()) {
      planType = 'pro_trial';
    }

    return planType;
  }

  static getCardInfo(billing) {
    if (!billing.card || !billing.card_brand) return null;
    const expirationDate = new Date(billing.card_exp_year, billing.card_exp_month);
    const isExpired = expirationDate < new Date();
    const formattedExpMonth =
      billing.card_exp_month &&
      (billing.card_exp_month < 10 ? `0${billing.card_exp_month}` : billing.card_exp_month);

    return {
      lastDigits: billing.card,
      brandName: billing.card_brand,
      brandKey: billing.card_brand?.replace(/[^a-z]/gi, '').toLowerCase(),
      expMonth: formattedExpMonth,
      expYear: billing.card_exp_year,
      zip: billing.card_address_zip,
      country: billing.country,
      zipRequired: billing.card_address_zip && billing.zipRequired,
      city: billing.city,
      state: billing.state,
      street: billing.street,
      card_address_country: billing.card_address_country,
      isExpired: isExpired,
    };
  }

  static getPricing(billing, seats, allSeats) {
    const taxAmountToday = getTaxAmount(seats, billing?.tax, 100);
    const taxAmountNext = getTaxAmount(allSeats, billing?.tax, 100);
    const planPeriodDays = planPeriodEndDay(billing.current_period_end);
    const seatAmount = getSeatAmount(allSeats, billing.pricing_plan);
    const oneDayPrice = getOneDayPrice(allSeats, billing);
    const oneDayTax = getOneDayTax(billing);
    const dueTodayPrice = getDueTodayPrice(seats, billing);

    const dueNextPrice = seatAmount * allSeats;
    const dueTodayCouponPrice = calcPriceWithCoupon(billing?.coupon, dueTodayPrice);
    const dueNextCouponPrice = calcPriceWithCoupon(billing?.coupon, dueNextPrice);
    const dueTodayPriceTotal =
      dueTodayPrice - dueTodayCouponPrice < 0
        ? 0
        : dueTodayPrice - dueTodayCouponPrice + taxAmountToday;
    const dueNextPriceTotal = dueNextPrice - dueNextCouponPrice + taxAmountNext;

    return {
      oneDayTax,
      seatAmount,
      oneDayPrice,
      dueNextPrice,
      dueTodayPrice,
      planPeriodDays,
      dueNextPriceTotal,
      dueTodayPriceTotal,
      dueNextCouponPrice,
      dueTodayCouponPrice,
    };
  }

  static calcUnusedCreditAmount(billing) {
    const oneDayPrice = getOneDayPrice(billing.seats.chargeSeatsCount, billing);
    const planExpireDaysLeft = getPlanExpirationDaysLeft(billing.current_period_end);
    return billing.type === 'paypal'
      ? 0
      : oneDayPrice * billing.seats.chargeSeatsCount * planExpireDaysLeft;
  }

  static calcUnusedTaxAmount(billing) {
    return getOneDayTax(billing) * getPlanExpirationDaysLeft(billing.current_period_end);
  }

  static getCurreny(account) {
    const currency = account.team?.currency ?? account.currency;
    const [usd, jpy] = Object.keys(planCurrencies);
    const isVcube = this.isVcube();
    if (this.isTeamFree() && !currency) {
      return !isVcube ? usd : jpy;
    }
    return currency;
  }

  static getActualPlanName(name, currency) {
    const isYenPrice = (!currency && this.isVcube()) || currency === 'jpy';
    const result = isYenPrice ? YenPlansMapping[name] : name;
    return result || name;
  }

  static getActualPlanNameByPlanType(planType, billingInterval, currency) {
    const isMonthly = billingInterval === 'month';
    const type = planType.toLowerCase();

    switch (type) {
      case 'pro':
        return isMonthly
          ? this.getActualPlanName('proMonthly', currency)
          : this.getActualPlanName('proAnnual', currency);
      case 'business':
        return isMonthly
          ? this.getActualPlanName('businessMonthly', currency)
          : this.getActualPlanName('businessAnnual', currency);
      case 'ma_business':
        return isMonthly
          ? this.getActualPlanName('maBusinessMonthly', currency)
          : this.getActualPlanName('maBusinessAnnual', currency);
      default:
        return null;
    }
  }

  static hasCard(billing) {
    if (billing.card && billing.card_brand) return true;
    if (billing.payment_type === 'link') return true;
    return false;
  }

  static getCurrencyShort(currency) {
    if (!currency) return '';
    return currency.toLowerCase() === 'usd' ? currency.substring(0, 2).toUpperCase() : '';
  }

  static getPlanInfo(pricing_plan) {
    return {
      isMonthly: pricing_plan?.billing_interval === 'month',
      isAnnual: pricing_plan?.billing_interval === 'year',
      isPro: pricing_plan?.type === 'pro',
    };
  }

  static bindPrice({ price, fix, altSymbol, currency }) {
    const currencySymbol = planCurrencies[currency];
    const symbol = altSymbol || currencySymbol || '';

    return `${symbol}${bindNum(price, fix)}`;
  }

  static isStripeLink(billing) {
    return billing.payment_type === 'link';
  }

  static isStripeTrial(billing) {
    return billing.subscription_status === 'trialing';
  }

  static isPaypalSubscription() {
    return PermissionService.hasAccess('team_subscription_paypal');
  }

  static isTrial() {
    return (
      PermissionService.hasAccess('reverse_trial_active') ||
      PermissionService.hasAccess('user_mode_free_pro')
    );
  }

  static isTeamTrial() {
    return PermissionService.hasAccess('team_trial');
  }

  static isVcube() {
    return (
      PermissionService.hasAccess('user_vcube') || PermissionService.hasAccess('team_type_vcube')
    );
  }

  static isTeamAdmin() {
    return PermissionService.hasAccess('team_admin');
  }

  static isTeamFree() {
    return PermissionService.hasAccess('team_free');
  }
}

export default BillingService;
