export const AUTH = {
  SET_STATE: '@@auth/SET_STATE',
  LOGIN: '@@auth/ON_LOGIN',
  LOGOUT: '@@auth/ON_LOGOUT',
};

export const USER = {
  SET_ACCOUNTS: '@@user/SET_ACCOUNTS',
  SET_SEATS: '@@user/SET_SEATS',
  SET_WAITLIST: '@@user/SET_WAITLIST',
  REMOVE_SEATS: '@@user/REMOVE_SEATS',
  SET_BLACKLIST: '@@user/SET_BLACKLIST',
  REMOVE_BLACKLIST: '@@user/REMOVE_BLACKLIST',
  SET_PREFERENCES: '@@user/SET_PREFERENCES',
  UPDATE_PREFERENCES_PARTIALLY: '@@user/UPDATE_PREFERENCES_PARTIALLY',
  SET_RECORDS: '@@user/SET_RECORDS',
  UPDATE_RECORD: '@@user/UPDATE_RECORD',
  SET_PERMISSIONS: '@@user/SET_PERMISSIONS',
  ADD_PERMISSION: '@@user/ADD_PERMISSION',
  ADD_PERMISSIONS: '@@user/ADD_PERMISSIONS',
  REMOVE_PERMISSION: '@@user/REMOVE_PERMISSION',
  REMOVE_PERMISSIONS: '@@user/REMOVE_PERMISSIONS',
  UPDATE_ACCOUNT_TEAM_NAME: '@@user/UPDATE_ACCOUNT_TEAM_NAME',
  UPDATE_ACCOUNT_DATA_PARTIALLY: '@@user/UPDATE_ACCOUNT_DATA_PARTIALLY',
  UPDATE_TEAM: '@@user/UPDATE_TEAM',
  UPDATE_ACCOUNT_DATA: '@@user/UPDATE_ACCOUNT_DATA',
  SET_DOMAINS: '@@user/SET_DOMAINS',
  UPDATE_DOMAIN: '@@user/UPDATE_DOMAIN',
  REMOVE_DOMAIN: '@@user/REMOVE_DOMAIN',
  REMOVE_DOMAINS: '@@user/REMOVE_DOMAINS',
  SET_CO_WORKERS: '@@user/SET_CO_WORKERS',
  SET_AUDIO_DEVICES: '@@user/SET_AUDIO_DEVICES',
  SET_MEETING: '@@user/SET_MEETING',
  SET_RECURRING_MEETINGS: '@@user/SET_RECURRING_MEETINGS',
  SET_MEETING_ACCESSES: '@@user/SET_MEETING_ACCESSES',
  DELETE_MEETING: '@@user/DELETE_MEETING',
  UPDATE_MEETING: '@@user/UPDATE_MEETING',
  UPDATE_MEETING_ROW: '@@user/UPDATE_MEETING_ROW',
  UPDATE_MEETING_TRANSCRIPT: '@@user/UPDATE_MEETING_TRANSCRIPT',
  UPDATE_MEETING_TRANSCRIPT_PARTIAL: '@@user/UPDATE_MEETING_TRANSCRIPT_PARTIAL',
  UPDATE_MEETING_NOTE_IN_LIST: '@@user/UPDATE_MEETING_NOTE_IN_LIST',
  TRIGGER_MEETING_NOTE_UPDATE: '@@user/TRIGGER_MEETING_NOTE_UPDATE',
  SET_ANALYTICS_DASHBOARD_TAB_INDEX: '@@user/SET_ANALYTICS_DASHBOARD_TAB_INDEX',
  SET_INSTALLATION_META: '@@user/SET_INSTALLATION_META',
  COLLECT_USER_CONTACTS: '@@user/COLLECT_USER_CONTACTS',
  SET_WORDS: '@@user/SET_WORDS',
  ADD_WORDS: '@@user/ADD_WORDS',
  UPDATE_WORD: '@@user/UPDATE_WORD',
  DELETE_WORD: '@@user/DELETE_WORD',
  SET_ONBOARDING_BANNER_OPEN: '@@user/SET_ONBOARDING_BANNER_OPEN',
  SET_NEW_SPEAKERS: '@@user/SET_NEW_SPEAKERS',
  UPDATE_MEETING_SPEAKER: '@@user/UPDATE_MEETING_SPEAKER',
  SET_AVAILABLE_TEAMS: '@@user/SET_AVAILABLE_TEAMS',
  CHECK_TRANSCRIPT_VISIBILITY_AFTER_SPEECH_EDIT:
    '@@user/CHECK_TRANSCRIPT_VISIBILITY_AFTER_SPEECH_EDIT',
  SET_SELECTED_ATTENDEES_FOR_ACCESS: '@@meeting/SET_SELECTED_ATTENDEES_FOR_ACCESS',
  SET_SELECTED_SPEAKER_TO_LISTEN: '@@meeting/SET_SELECTED_SPEAKER_TO_LISTEN',
};

export const MEETING = {
  SET_MEETINGS: '@@meeting/SET_MEETINGS',
  SET_HISTORY_LOCKED_COUNT: '@@meeting/SET_HISTORY_LOCKED_COUNT',
  TOGGLE_REMOVED_MEETING_IDS: '@@meeting/TOGGLE_REMOVED_MEETING_IDS',
  CHANGE_MEETING_INTERACTION: '@@meeting/CHANGE_MEETING_INTERACTION',
};

export const AGENDA = {
  SET_AGENDAS: '@@agenda/SET_AGENDAS',
  RESET_AGENDAS: '@@agenda/RESET_AGENDAS',
  UPDATE_ORGANIZER_CONTACTS: '@@agenda/UPDATE_ORGANIZER_CONTACTS',
  UPDATE_AGENDA_TOPICS: '@@agenda/UPDATE_AGENDA_TOPICS',
  UPDATE_AGENDA_TOPIC_ITEM: '@@agenda/UPDATE_AGENDA_TOPIC_ITEM',
  UPDATE_AGENDA: '@@agenda/UPDATE_AGENDA',
};

export const ACTIVITY = {
  SET_ACTIVITIES: '@@activity/SET_ACTIVITIES',
  SET_ACTIVITY: '@@activity/SET_ACTIVITY',
  SET_TOTALS: '@@activity/SET_TOTALS',
  SET_ACTIVITY_VISIBILITY: '@@activity/SET_ACTIVITY_VISIBILITY',
  SET_GROUPS: '@@activity/SET_GROUPS',
  MART_ALL_AS_READ: '@@activity/MART_ALL_AS_READ',
};

export const RESOURCE = {
  SET_CHAT: '@@resource/SET_CHAT',
};

export const BILLING = {
  SET_PLANS: '@@billing/SET_PLANS',
  SET_INVOICES: '@@billing/SET_INVOICES',
  SET_TEAM_STATUS: '@@billing/SET_TEAM_STATUS',
  REMOVE_INVOICES: '@@billing/REMOVE_INVOICES',
  SET_BILLING: '@@billing/SET_BILLING',
  REMOVE_BILLING: '@@billing/REMOVE_BILLING',
  UPDATE_UNASSIGNED_SEATS_COUNT: '@@billing/UPDATE_UNASSIGNED_SEATS_COUNT',
  BILLING_AB_TEST: '@@billing/BILLING_AB_TEST',
};

export const INSIGHT = {
  SET_FILTER: '@@insight/SET_FILTER',
  SET_SUMMARY_DATA: '@@insight/SET_SUMMARY_DATA',
};

export const EXPERIMENT = {
  ADD_EXPERIMENT: '@@amplitude_experiments/ADD_EXPERIMENT',
  REMOVE_EXPERIMENT: '@@amplitude_experiments/REMOVE_EXPERIMENT',
};

export const WORKSPACE = {
  SET_PREVIEW_DATA: '@@workspace/SET_PREVIEW_DATA',
};

export const PLAYER = {
  SET_TALKING_SPEAKER: '@@player/SET_TALKING_SPEAKER',
  SET_CURRENT_TIME: '@@player/SET_CURRENT_TIME',
  RESET_CURRENT_TIME: '@@player/RESET_CURRENT_TIME',
};
