import { createReducer } from 'utils/reduxHelpers';
import { AUTH, WORKSPACE } from 'redux_store/actionTypes';

const initialState = null;

const handlers = {
  [WORKSPACE.SET_PREVIEW_DATA]: (state, action) => action.payload,
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
