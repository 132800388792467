import { createReducer } from 'utils/reduxHelpers';
import { AUTH, USER } from 'redux_store/actionTypes';

const initialState = [];

const handlers = {
  [USER.SET_PERMISSIONS]: (state, action) => action.payload,
  [USER.ADD_PERMISSION]: (state, action) => {
    if (state.includes(action.payload)) return state;
    return [...state, action.payload];
  },
  [USER.ADD_PERMISSIONS]: (state, action) => {
    const newState = [...state];
    action.payload.map((perm) => {
      if (!state.includes(perm)) newState.push(perm);
    });
    return newState;
  },
  [USER.REMOVE_PERMISSION]: (state, action) => {
    if (!state.includes(action.payload)) return state;
    return state.filter((perm) => perm !== action.payload);
  },
  [USER.REMOVE_PERMISSIONS]: (state, action) => {
    if (!action.payload.length) return state;
    return state.filter((perm) => !action.payload.includes(perm));
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
