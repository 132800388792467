import { useEffect } from 'react';

const useScript = (params = {}) => {
  useEffect(() => {
    const script = document.createElement('script');

    Object.keys(params).forEach((key) => script.setAttribute(key, params[key]));

    document.body.appendChild(script);
    return () => document.body.removeChild(script);
  }, [params]);
};

export default useScript;
