import * as Sentry from '@sentry/react';
// import { BrowserTracing } from '@sentry/tracing';
import { ACCESS_TOKENS, getENV } from './configs';

const initSentry = () => {
  const release = import.meta.env.VITE_VERSION;
  if (!release) return;
  const env = getENV();
  const releaseName = release + (env === 'prod' ? '' : `-${env}`);
  if (['prod', 'stage', 'dev', 'plive'].includes(env)) {
    Sentry.init({
      dsn: ACCESS_TOKENS.SENTRY_DSN,
      // integrations: [new BrowserTracing()],
      // tracesSampleRate: env === 'prod' ? 0.03 : 1.0,
      release: releaseName,
      environment: env,
      denyUrls: [/extensions\//i, /^chrome:\/\//i, /^safari-extension:\/\//i],
      // eslint-disable-next-line spellcheck/spell-checker
      ignoreErrors: ['fbq is not defined', "Can't find variable: fbq"],
    });
  }
};

export default initSentry;
