import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { snackbarSettings } from './configs';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './redux_store/store';
import './lang';
import 'scss/app.scss';
import App from './app';
import InlineScripts from './InlineScripts';
import AppLoading from 'shared/components/loaders/AppLoading';
import ErrorBoundary from 'shared/components/layout/ErrorBoundary';
import RouteTitle from 'shared/components/layout/RouteTitle';
import initSentry from './initSentry';
import initOneTrust from './initOneTrust';
import initHotjar from './initHotjar';
import { SocketProvider } from 'providers/SocketProvider';
import { BreadcrumbProvider } from '@krispai/kr-ui-components';

initSentry();
initOneTrust();
initHotjar();

const root = createRoot(document.getElementById('root'));

root.render(
  <InlineScripts>
    <Provider store={store}>
      <SocketProvider>
        <SnackbarProvider {...snackbarSettings}>
          <Suspense fallback={<AppLoading />}>
            <ErrorBoundary>
              <Router>
                <BreadcrumbProvider>
                  <RouteTitle>
                    <App />
                  </RouteTitle>
                </BreadcrumbProvider>
              </Router>
            </ErrorBoundary>
          </Suspense>
        </SnackbarProvider>
      </SocketProvider>
    </Provider>
  </InlineScripts>,
);
