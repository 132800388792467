import { createReducer } from 'utils/reduxHelpers';
import { AUTH, AGENDA } from 'redux_store/actionTypes';
import cloneDeep from 'lodash/cloneDeep';

const initialState = { items: {}, hosts: [] };

const handlers = {
  [AGENDA.SET_AGENDAS]: (state, action) => {
    const currentItems = state.items || {};
    const itemsByID = action.payload.items.reduce((acc, agenda) => {
      acc[agenda.id] = { ...agenda, items: currentItems[agenda.id]?.items || undefined };
      return acc;
    }, {});
    return { items: itemsByID, hosts: action.payload.hosts };
  },
  [AGENDA.UPDATE_ORGANIZER_CONTACTS]: (state, action) => {
    const newStateData = cloneDeep({ ...state.items });
    Object.keys(newStateData).forEach((id) => {
      const event = newStateData[id];
      const currentOrganizer = action.payload.find(
        (c) => event.organizer && event.organizer?.email === c.email,
      );
      if (currentOrganizer) {
        newStateData[id].organizer = { ...event.organizer, ...currentOrganizer };
      }
    });
    return { ...state, items: newStateData };
  },
  [AGENDA.UPDATE_AGENDA]: (state, action) => {
    const newStateData = cloneDeep({ ...state.items });
    newStateData[action.payload.id] = {
      ...newStateData[action.payload.id],
      agenda: action.payload.agenda,
    };
    return { ...state, items: newStateData };
  },
  [AGENDA.UPDATE_AGENDA_TOPICS]: (state, action) => {
    const newStateData = cloneDeep({ ...state.items });
    Object.keys(action.payload).forEach((agendaID) => {
      if (newStateData[agendaID]) {
        newStateData[agendaID].items = action.payload[agendaID];
      }
    });
    return { ...state, items: newStateData };
  },
  [AGENDA.UPDATE_AGENDA_TOPIC_ITEM]: (state, action) => {
    const newStateData = cloneDeep({ ...state.items });
    const { agendaID, item } = action.payload;
    if (newStateData[agendaID]) {
      const itemIndex = newStateData[agendaID].items.findIndex((i) => i.id === item.id);
      if (itemIndex !== -1) {
        newStateData[agendaID].items[itemIndex] = item;
      }
    }
    return { ...state, items: newStateData };
  },
  [AUTH.LOGOUT]: () => initialState,
};

export default createReducer(initialState, handlers);
