import { reg } from 'configs';
import { bindNum } from './appHelpers';

export const getTaxAmount = (seatsCount, tax = 0, fix = 2) => {
  return bindNum(seatsCount * tax, fix);
};

export const getUpcomingTaxAmount = (nextPrice, currentTax, currentPrice) => {
  return bindNum((nextPrice * currentTax) / currentPrice, 2);
};
export const getAddress = ({
  address_country,
  address_zip,
  address_line1,
  address_city,
  address_state,
}) => {
  let address = { address_country };
  if (address_zip) address['address_zip'] = address_zip;
  if (address_line1) address['address_line1'] = address_line1;
  if (address_city) address['address_city'] = address_city;
  if (address_state) address['address_state'] = address_state;
  return address;
};

export const validateAddressInfo = ({ zipOnly = false, card_zip, cardData, taxPercent }) => {
  const { address_line1, address_city, address_state, address_country, address_zip } = cardData;

  // Validate Zip Code in case of Canada
  if (address_country === 'CA') {
    return address_zip ? reg.canadianPostalCodeReg.test(address_zip) : true;
  }

  if (address_country !== 'US') return true;

  if (!zipOnly) {
    if (
      address_line1 === '' ||
      address_city === '' ||
      address_state === '' ||
      typeof taxPercent !== 'number'
    ) {
      return false;
    }
  } else if (!card_zip && typeof taxPercent !== 'number') {
    return false;
  }

  return true;
};
