import { v4 as uuidv4 } from 'uuid';
import { getENV } from 'configs';

export const getAmplitudeUserId = (account) => {
  const userId = account?.id;
  const teamId = account?.team?.id;
  if (!userId || !teamId) return;
  return `${userId}_${teamId}`;
};

export const logAnalyticsEventsForAutomation = (data) => {
  if (getENV() !== 'prod') {
    if (!window.__event_log) window.__event_log = [];
    window.__event_log.push(data);
  }
};

export const generateSessionId = () => uuidv4();

export const generateAnonymousId = () => uuidv4();
